import '@fontsource/inter/variable.css';

import {ChakraProvider} from '@chakra-ui/react';
import type {DocumentTypeDecoration} from '@graphql-typed-document-node/core';
import FirebaseProvider from 'FirebaseProvider';
import type {DocumentNode} from 'graphql';
import {theme} from 'theme';
import {AnyVariables, OperationContext, RequestPolicy, UseQueryResponse} from 'urql';
import WalletProvider from 'WalletProvider';

declare module 'urql' {
  export type UseQueryArgs<
    Variables extends AnyVariables = AnyVariables,
    Data = unknown
  > = {
    query: string | DocumentNode | DocumentTypeDecoration<Data, Variables>;
    requestPolicy?: RequestPolicy;
    context?: Partial<OperationContext>;
    pause?: boolean;
  } & (Variables extends void
    ? {
        variables?: Variables;
      }
    : Variables extends {
        [P in keyof Variables]: Variables[P] | null;
      }
    ? {
        variables?: Variables;
      }
    : {
        variables: Variables;
      });

  export function useQuery<Data = unknown, Variables extends AnyVariables = AnyVariables>(
    args: UseQueryArgs<Variables, Data>
  ): UseQueryResponse<Data, Variables>;
}

function App() {
  return (
    <ChakraProvider theme={theme}>
      <WalletProvider>
        <FirebaseProvider />
      </WalletProvider>
    </ChakraProvider>
  );
}

export default App;
