import {theme as proTheme} from '@chakra-ui/pro-theme';
import {Colors, extendTheme, theme as baseTheme} from '@chakra-ui/react';

const colors: Colors = {
  brand: {
    50: '#FAF5FF',
    100: '#E9D8FD',
    200: '#D6BCFA',
    300: '#B794F4',
    400: '#9F7AEA',
    500: '#8548FF',
    600: '#6B46C1',
    700: '#553C9A',
    800: '#44337A',
    900: '#322659',
  },
};

export const theme = extendTheme(
  {
    colors: {...baseTheme.colors, brand: colors.brand, purple: colors.brand},
    components: {
      Button: {
        baseStyle: {
          rounded: '2xl',
        },
      },
      Tag: {
        baseStyle: {
          rounded: '2xl',
        },
      },
      Badge: {
        baseStyle: {
          rounded: '2xl',
        },
      },
      Card: {
        sizes: {
          md: {
            container: {
              borderRadius: '2xl',
            },
          },
        },
      },
    },
  },
  proTheme
);
