import {Divider, Flex, Stack} from '@chakra-ui/react';
import Logo from 'components/Logo';
import {HiOutlineCog6Tooth} from 'react-icons/hi2';

import type {NavItem} from './NavBar';
import {NavButton} from './NavButton';
import {UserProfile} from './UserProfile';

interface Props {
  items: NavItem[];
  accountId: string;
}

export const Sidebar = ({items, accountId}: Props) => (
  <Flex as='section' minH='100vh' bg='bg-canvas'>
    <Flex
      flex='1'
      bg='bg-surface'
      overflowY='auto'
      boxShadow='sm'
      maxW={{base: 'full', sm: 'xs'}}
      py={{base: '6', sm: '8'}}
      px={{base: '4', sm: '6'}}>
      <Stack justify='space-between' spacing='1'>
        <Stack spacing={{base: '5', sm: '6'}} shouldWrapChildren>
          <Logo h='8' />

          <Stack spacing='1'>
            {items.map(i => (
              <NavButton {...i} key={i.to} />
            ))}
          </Stack>
        </Stack>
        <Stack spacing={{base: '5', sm: '6'}}>
          <Stack spacing='1'>
            <NavButton
              title='Settings'
              to={`/${accountId}/settings`}
              icon={HiOutlineCog6Tooth}
            />
          </Stack>
          <Divider />
          <UserProfile />
        </Stack>
      </Stack>
    </Flex>
  </Flex>
);
