import {Avatar, Box, HStack, Text} from '@chakra-ui/react';
import useProfile from 'hooks/useProfile';
import {useMemo} from 'react';
import gravatar from 'utils/gravatar';

export const UserProfile = () => {
  const {user} = useProfile();

  const avatarUri = useMemo(
    () => (user ? user.avatarURI || gravatar(user.email) : undefined),
    [user]
  );
  return (
    <HStack spacing='3' ps='2'>
      <Avatar name={user?.fullName || undefined} src={avatarUri} boxSize='10' />
      <Box>
        <Text fontWeight='medium' fontSize='sm'>
          {user?.fullName}
        </Text>
        <Text color='muted' fontSize='sm'>
          {user?.email}
        </Text>
      </Box>
    </HStack>
  );
};
