import {Box, Spinner, VStack} from '@chakra-ui/react';

import Logo from './Logo';

interface LoaderProps {
  loading?: boolean;
  children?: React.ReactNode;
}

const Loader = ({loading, children}: LoaderProps) => {
  return (
    <Box
      minH='100vh'
      flex={1}
      display={'flex'}
      justifyContent='center'
      alignItems={'center'}>
      <VStack spacing={'6'}>
        <Logo height='12' />
        {loading && <Spinner size={'lg'} />}
        {!loading && children}
      </VStack>
    </Box>
  );
};

export default Loader;
