import {Box, Container} from '@chakra-ui/react';
import {Outlet} from 'react-router-dom';

import {Navbar} from './NavBar';

const NavbarLayout = () => {
  return (
    <Box as='section' height='100vh' overflowY='auto'>
      <Navbar />
      <Container pt={{base: '8', lg: '12'}} pb={{base: '12', lg: '24'}}>
        <Outlet />
      </Container>
    </Box>
  );
};

export default NavbarLayout;
