import {Text} from '@chakra-ui/react';
import AppProvider from 'AppProvider';
import Loader from 'components/Loader';
import {makeClient} from 'gql/urql';
import {useMemo} from 'react';
import {useIdToken} from 'react-firebase-hooks/auth';
import {Provider} from 'urql';
import {auth} from 'utils/firebase';

const FirebaseProvider = () => {
  const [user, loading, error] = useIdToken(auth);

  console.log('FirebaseProvider -> ', loading, !!user);

  const urqlClient = useMemo(() => {
    return makeClient(user || undefined);
  }, [user]);

  if (loading) {
    return <Loader loading />;
  }

  if (error) {
    return (
      <Loader loading={false}>
        <Text>There is an error, please check the network connection again</Text>
      </Loader>
    );
  }

  return (
    <Provider value={urqlClient}>
      <AppProvider firebaseUser={user || null} />
    </Provider>
  );
};

export default FirebaseProvider;
