import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Radio,
  RadioGroup,
  Stack,
  VStack,
} from '@chakra-ui/react';
import SelectCurrency from 'components/SelectCurrency';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {graphql} from 'gql';
import useAccount from 'hooks/useAccount';
import useToast from 'hooks/useToast';
import {useCallback} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useMutation, useQuery} from 'urql';

type NewAccountProps = Omit<ModalProps, 'children'> & {
  onCreated?: (accountId: string) => void;
};

type FormValues = {
  name: string;
  currency: string;
  type: 'merchant' | 'platform';
};

dayjs.extend(utc);
dayjs.extend(timezone);

const UserAccountsDoc = graphql(`
  query UserAccounts($userId: String = "") {
    user: usersByPk(id: $userId) {
      id
      defaultAccountId
      ownerAccounts: accounts {
        ...AccountItem
      }
      memberAccounts: accountMembers {
        ...AccountMemberItem
        account {
          ...AccountItem
        }
      }
    }
  }
`);

const createNewAccountDoc = graphql(`
  mutation createNewAccount($object: AccountsInsertInput = {}) {
    insertAccountsOne(object: $object) {
      id
      logoURI
      name
    }
  }
`);

const NewAccount = ({...props}: NewAccountProps) => {
  const {userId} = useAccount();
  const {combinedErrorToast} = useToast();
  const [, createNewAccount] = useMutation(createNewAccountDoc.toString());
  const [, fetchAccounts] = useQuery({
    query: UserAccountsDoc.toString(),
    variables: {userId: userId},
    requestPolicy: 'network-only',
    pause: true,
  });

  const {
    handleSubmit,
    control,
    register,
    reset,
    formState: {errors, isSubmitting},
  } = useForm<FormValues>({defaultValues: {type: 'merchant', currency: 'usd'}});

  const getTzInfo = useCallback(async () => {
    const tz = dayjs.tz.guess();
    const zones = (await import('../../utils/timezones')).default.zones;
    const zone = zones.find(z => z.id === tz || z.aliases.includes(tz));
    return zone;
  }, []);

  const onSubmit = useCallback(
    async (values: FormValues) => {
      const tz = dayjs.tz.guess();
      const zone = await getTzInfo();
      const {data, error} = await createNewAccount({
        object: {
          name: values.name,
          defaultCurrencyId: values.currency,
          tz,
          countryId: zone?.location?.countryCode,
        },
      });
      if (data?.insertAccountsOne) {
        await fetchAccounts();
        props.onCreated?.(data?.insertAccountsOne.id);
        props.onClose();
        reset({});
      }
      if (error) {
        combinedErrorToast(error);
      }
    },
    [combinedErrorToast, createNewAccount, fetchAccounts, getTzInfo, props, reset]
  );

  return (
    <Modal {...props} isCentered closeOnOverlayClick={false} closeOnEsc={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create a new account</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing='2'>
            <FormControl isDisabled={isSubmitting} isInvalid={!!errors.name}>
              <FormLabel>Name</FormLabel>
              <Input
                type='text'
                {...register('name', {
                  required: true,
                  minLength: 3,
                  maxLength: 50,
                })}
              />
            </FormControl>

            <Controller
              control={control}
              name='currency'
              rules={{required: false}}
              render={({
                field: {value, onChange, onBlur},
                formState: {errors, isSubmitting},
              }) => (
                <FormControl isDisabled={isSubmitting} isInvalid={!!errors.currency}>
                  <FormLabel>Default currency</FormLabel>
                  <SelectCurrency
                    value={value}
                    onChange={v => onChange(v)}
                    onBlur={onBlur}
                  />
                </FormControl>
              )}
            />

            <Controller
              control={control}
              name='type'
              rules={{required: true}}
              render={({
                field: {value, onChange, onBlur},
                formState: {errors, isSubmitting},
              }) => (
                <FormControl isDisabled={isSubmitting} isInvalid={!!errors.type}>
                  <FormLabel>Type</FormLabel>
                  <RadioGroup value={value} onBlur={onBlur} onChange={v => onChange(v)}>
                    <Stack direction='row'>
                      <Radio value='merchant'>Merchant</Radio>
                      <Radio value='platform'>Platform</Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
              )}
            />
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button
            mr={3}
            onClick={handleSubmit(onSubmit)}
            isDisabled={isSubmitting}
            isLoading={isSubmitting}>
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default NewAccount;
