import {configureChains, createConfig} from 'wagmi';
import {
  arbitrum,
  avalanche,
  bsc,
  bscTestnet,
  goerli,
  mainnet,
  optimism,
  polygon,
  polygonMumbai,
} from 'wagmi/chains';
import {jsonRpcProvider} from 'wagmi/providers/jsonRpc';

import {getRpcUrl} from './ankrRpc';

const {publicClient} = configureChains(
  [
    mainnet,
    polygon,
    arbitrum,
    avalanche,
    bsc,
    optimism,
    bscTestnet,
    polygonMumbai,
    goerli,
  ],
  [
    jsonRpcProvider({
      rpc: pChain => {
        const url = getRpcUrl(pChain.id);
        return url ? {http: url} : null;
      },
    }),
  ]
);

export const config = createConfig({
  autoConnect: false,
  publicClient,
});
