import {useToast as useToastUI} from '@chakra-ui/react';
import {useCallback} from 'react';
import {CombinedError} from 'urql';

interface InputToast {
  title?: string;
  description?: string;
  duration?: number;
  id?: string;
}

const useToast = () => {
  const toast = useToastUI();

  const successToast = useCallback(
    ({title, description, duration = 2500, id}: InputToast) => {
      toast({
        title: title || 'Success',
        description: description,
        position: 'top-right',
        status: 'success',
        duration: duration,
        isClosable: true,
        id,
      });
    },
    [toast]
  );

  const errorToast = useCallback(
    ({title, description, duration = 3000, id}: InputToast) => {
      toast({
        title: title || 'Ops!',
        description: description,
        position: 'top-right',
        status: 'error',
        duration: duration,
        isClosable: true,
        id,
      });
    },
    [toast]
  );

  const combinedErrorToast = useCallback(
    (error: CombinedError) => {
      let description = 'Some thing went wrong';
      console.error('error', error);
      if (error.graphQLErrors) {
        if (error.graphQLErrors.some(e => e.message.includes('check_email'))) {
          errorToast({
            title: 'Ops!',
            description: 'Email is invalid',
          });
          return;
        }

        const isLength = error.graphQLErrors.some(e =>
          e.message.includes('check_length')
        );
        const isStoreNameExists = error.graphQLErrors.some(e =>
          e.message.includes('App_storeName_key')
        );
        const isAppNumberFull = error.graphQLErrors.some(e =>
          e.message.includes(`Account can't have more than`)
        );
        if (isLength) {
          description = 'Please check the data, length is invalid';
        } else if (isStoreNameExists) {
          description = 'Store name is already exists';
        } else if (isAppNumberFull) {
          description = 'Account has reached its limit';
        }
      }
      errorToast({
        title: 'Ops!',
        description: description,
      });
    },
    [errorToast]
  );

  return {successToast, errorToast, combinedErrorToast};
};

export default useToast;
