import {Box, useDisclosure} from '@chakra-ui/react';
import SelectAccount from 'components/SelectAccount';
import useAccount from 'hooks/useAccount';
import NewAccount from 'pages/account/NewModal';
import {useCallback} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

const SwitchAccount = () => {
  const {currentAccountId, switchAccount, accounts} = useAccount();
  const {pathname} = useLocation();
  const {isOpen, onOpen, onClose} = useDisclosure({defaultIsOpen: false});
  const navigate = useNavigate();
  const onAccountChange = useCallback(
    (accountId: string) => {
      if (accountId === currentAccountId || !currentAccountId) return;
      const isOk = switchAccount(accountId);
      if (!isOk) return;
      const newPath = pathname.replace(currentAccountId, accountId);
      navigate(newPath);
    },
    [currentAccountId, switchAccount, pathname, navigate]
  );
  const canCreate = accounts.length <= 15;
  const onCreated = useCallback(
    (newAccountId: string) => {
      setTimeout(() => {
        if (!currentAccountId) return;

        if (switchAccount(newAccountId)) {
          const newPath = pathname.replace(currentAccountId, newAccountId);
          navigate(newPath);
        }
      }, 1000);
    },
    [currentAccountId, navigate, pathname, switchAccount]
  );
  return (
    <Box>
      <SelectAccount
        showButtonNew={canCreate}
        value={currentAccountId}
        isHeader
        onChange={onAccountChange}
        onNewAccount={onOpen}
      />
      <NewAccount isOpen={isOpen} onClose={onClose} onCreated={onCreated} />
    </Box>
  );
};

export default SwitchAccount;
