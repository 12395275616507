import {graphql} from 'gql';
import {useQuery} from 'urql';

import useAccount from './useAccount';

export const UserFragment = graphql(`
  fragment UserItem on Users {
    id
    fullName
    email
    lastSeen
    phoneNumber
    publicAddress
    type
    defaultAccountId
    createdAt
    countryId
    city
    avatarURI
    address
    updatedAt
  }
`);

const GetUserDoc = graphql(`
  query GetUser($id: String = "") {
    user: usersByPk(id: $id) {
      ...UserItem
    }
  }
`);

const useProfile = () => {
  const {userId} = useAccount();
  const [{data, fetching, error}] = useQuery({
    query: GetUserDoc.toString(),
    variables: {id: userId},
  });
  return {user: data?.user, fetching, error};
};

export default useProfile;
