import {type User} from 'firebase/auth';
import {type Account} from 'gql/types';
import {createContext} from 'react';

type AppContextValues = {
  isInitializing: boolean;
  isLogin: boolean;
  firebaseUser: User | null;
  accounts: Account[];
  currentAccount?: Account;
  setCurrentAccount: (account?: Account) => void;
  setAccounts: (accounts: Account[]) => void;
  isTestMode: boolean;
  toggleTestMode: () => void;
  logOut: () => void;
};

const AppContext = createContext<AppContextValues>({} as AppContextValues);

export default AppContext;
