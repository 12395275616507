import {
  Avatar,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Text,
} from '@chakra-ui/react';
import useAccount from 'hooks/useAccount';
import useProfile from 'hooks/useProfile';
import {useMemo} from 'react';
import {Link} from 'react-router-dom';
import gravatar from 'utils/gravatar';

interface Props {
  isDesktop?: boolean;
}

const NavUser = ({isDesktop}: Props) => {
  const {user} = useProfile();
  const {mixAccountUrl, logOut} = useAccount();

  const avatarUri = useMemo(
    () => (user ? user.avatarURI || gravatar(user.email) : undefined),
    [user]
  );

  return (
    <Popover trigger={isDesktop ? 'hover' : 'click'}>
      <PopoverTrigger>
        <Avatar
          cursor='pointer'
          boxSize='10'
          name={user?.fullName || undefined}
          src={avatarUri}
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverHeader>
          <Stack spacing={0} gap={0}>
            <Text textColor='ButtonText' fontSize='xl'>
              {user?.fullName}
            </Text>
            <Text textColor='subtle' fontSize='sm'>
              {user?.email}
            </Text>
          </Stack>
        </PopoverHeader>
        <PopoverBody>
          <Stack spacing='3' py='2'>
            <Link to={mixAccountUrl('/settings/profile')}>
              <Button
                size='sm'
                color='brand.500'
                variant='link'
                justifyContent='flex-start'>
                Profile
              </Button>
            </Link>

            <Button
              size='sm'
              color='brand.500'
              variant='link'
              onClick={logOut}
              justifyContent='flex-start'>
              Sign out
            </Button>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default NavUser;
