import {WagmiConfig} from 'wagmi';

import {config} from './chains/wagmi';

interface WalletProviderProps {
  children: React.ReactNode;
}

const WalletProvider = ({children}: WalletProviderProps) => {
  return <WagmiConfig config={config}>{children}</WagmiConfig>;
};

export default WalletProvider;
