import {Button, Text} from '@chakra-ui/react';
import AppContext from 'AppContext';
import Loader from 'components/Loader';
import {User} from 'firebase/auth';
import {graphql} from 'gql';
import {AccountItemFragment, AccountMemberItemFragment} from 'gql/graphql';
import {Account} from 'gql/types';
import {useCallback, useEffect, useState} from 'react';
import {RouterProvider, useParams} from 'react-router-dom';
import router from 'router';
import {useMutation, useQuery} from 'urql';
import {mixAccounts} from 'utils/accountUtil';
import {auth} from 'utils/firebase';

interface AppProviderProps {
  firebaseUser: User | null;
}

const InitUserDoc = graphql(`
  mutation Initial($avatarURI: String) {
    initial(avatarURI: $avatarURI) {
      user {
        id
        defaultAccountId
        ownerAccounts: accounts {
          ...AccountItem
        }
        memberAccounts: accountMembers {
          ...AccountMemberItem
          account {
            ...AccountItem
          }
        }
      }
    }
  }
`);

const UserAccountsDoc = graphql(`
  query UserAccounts($userId: String = "") {
    user: usersByPk(id: $userId) {
      id
      defaultAccountId
      ownerAccounts: accounts {
        ...AccountItem
      }
      memberAccounts: accountMembers {
        ...AccountMemberItem
        account {
          ...AccountItem
        }
      }
    }
  }
`);

const AppProvider = ({firebaseUser}: AppProviderProps) => {
  const [isInitializing, setInitializing] = useState(!!firebaseUser);
  const [currentAccount, setCurrentAccount] = useState<Account>();
  const [accounts, setAccounts] = useState<Account[]>([]);
  const {accountId} = useParams();
  const [isTestMode, setTestMode] = useState(
    localStorage.getItem('isTestMode') === 'true'
  );

  const [{fetching: initFetching, error: errorInitial}, initial] = useMutation(
    InitUserDoc.toString()
  );

  const [{data: userAccountsData, fetching, error}, reload] = useQuery({
    query: UserAccountsDoc.toString(),
    variables: {userId: firebaseUser?.uid},
    pause: !firebaseUser || isInitializing,
    requestPolicy: 'cache-and-network',
  });

  const processData = useCallback(
    (
      ownerAccounts: AccountItemFragment[],
      memberAccounts: Array<
        AccountMemberItemFragment & {
          account: AccountItemFragment;
        }
      >,
      defaultAccountId?: string | null
    ) => {
      const accounts = mixAccounts(ownerAccounts, memberAccounts);
      setAccounts(accounts);
      const selectAccountId =
        localStorage.getItem('selectAccountId') || accountId || defaultAccountId;
      const isAccountEmpty = accounts.length === 0;
      if (!isAccountEmpty) {
        const selectAccount = accounts.find(a => a.id === selectAccountId) || accounts[0];
        setCurrentAccount(selectAccount);
        localStorage.setItem('selectAccountId', selectAccount.id);
      }
    },
    [accountId]
  );

  // For update user data
  useEffect(() => {
    const user = userAccountsData?.user;
    if (user) {
      console.log('AppProvider have user data');
      processData(user.ownerAccounts, user.memberAccounts, user.defaultAccountId);
    } else {
      if (user === null) {
        console.log('AppProvider Need init user data');
      }
    }
  }, [processData, userAccountsData]);

  useEffect(() => {
    if (!firebaseUser) {
      setAccounts([]);
      setCurrentAccount(undefined);
      localStorage.clear();
    }
  }, [firebaseUser]);

  const doInitial = useCallback(async () => {
    if (initFetching || !isInitializing) {
      return;
    }
    console.log('AppProvider doInitial.....');
    const avatarURI = firebaseUser?.photoURL;
    const {data} = await initial({avatarURI});
    if (data && data.initial && data.initial.user) {
      const user = data.initial.user;
      processData(user.ownerAccounts, user.memberAccounts, user.defaultAccountId);
      setInitializing(false);
    }
  }, [firebaseUser, initFetching, initial, isInitializing, processData]);

  useEffect(() => {
    (async () => {
      doInitial();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logOut = useCallback(async () => {
    await auth.signOut();
  }, []);

  const toggleTestMode = useCallback(() => {
    setTestMode(!isTestMode);
    localStorage.setItem('isTestMode', (!isTestMode).toString());
  }, [isTestMode]);

  if (isInitializing || fetching || initFetching) {
    return <Loader loading />;
  }

  if (error || errorInitial) {
    return (
      <Loader loading={false}>
        <Text>Something went wrong</Text>
        <Button onClick={reload}>Reload</Button>
      </Loader>
    );
  }

  return (
    <AppContext.Provider
      value={{
        isTestMode,
        isInitializing,
        isLogin: !!firebaseUser,
        accounts,
        firebaseUser,
        currentAccount,
        logOut,
        setAccounts,
        toggleTestMode,
        setCurrentAccount,
      }}>
      <RouterProvider router={router} />
    </AppContext.Provider>
  );
};

export default AppProvider;
