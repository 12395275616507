import {Progress} from '@chakra-ui/react';
import {ReactNode, Suspense} from 'react';

type RouteLoaderProps = {
  children: ReactNode;
};
const RouteLoader = ({children}: RouteLoaderProps) => {
  return (
    <Suspense
      fallback={
        <Progress
          size='xs'
          isIndeterminate
          position='absolute'
          top='-6'
          left='0'
          right='0'
        />
      }>
      {children}
    </Suspense>
  );
};

export default RouteLoader;
