import {Button} from '@chakra-ui/react';
import {Link, useMatch} from 'react-router-dom';

interface Props {
  to: string;
  title: string;
}

const NavLink = ({title, to}: Props) => {
  const match = useMatch(`${to}/*`);
  return (
    <Link to={to}>
      <Button aria-current={match ? 'page' : 'false'}>{title}</Button>
    </Link>
  );
};

export default NavLink;
