/* eslint-disable */
import * as types from './graphql';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  mutation Initial($avatarURI: String) {\n    initial(avatarURI: $avatarURI) {\n      user {\n        id\n        defaultAccountId\n        ownerAccounts: accounts {\n          ...AccountItem\n        }\n        memberAccounts: accountMembers {\n          ...AccountMemberItem\n          account {\n            ...AccountItem\n          }\n        }\n      }\n    }\n  }\n':
    types.InitialDocument,
  '\n  query UserAccounts($userId: String = "") {\n    user: usersByPk(id: $userId) {\n      id\n      defaultAccountId\n      ownerAccounts: accounts {\n        ...AccountItem\n      }\n      memberAccounts: accountMembers {\n        ...AccountMemberItem\n        account {\n          ...AccountItem\n        }\n      }\n    }\n  }\n':
    types.UserAccountsDocument,
  '\n  query getCoin($id: String = "") {\n    coinsByPk(id: $id) {\n      ...CoinItem\n    }\n  }\n':
    types.GetCoinDocument,
  '\n  fragment CountryItem on Countries {\n    id\n    dialCode\n    emoji\n    name\n  }\n':
    types.CountryItemFragmentDoc,
  '\n  query GetCountries {\n    countries {\n      ...CountryItem\n    }\n  }\n':
    types.GetCountriesDocument,
  '\n  fragment CurrencyItem on Currencies {\n    id\n    name\n    rate\n    symbol\n    logoURI\n    updatedAt\n  }\n':
    types.CurrencyItemFragmentDoc,
  '\n  query GetCurrencies @cached {\n    currencies {\n      ...CurrencyItem\n    }\n  }\n':
    types.GetCurrenciesDocument,
  '\n  fragment ChainItem on Chains {\n    id\n    name\n    isTestnet\n    logoURI\n    coinId\n  }\n':
    types.ChainItemFragmentDoc,
  '\n  query allChains {\n    chains: chains(orderBy: {isTestnet: ASC}) {\n      ...ChainItem\n    }\n  }\n':
    types.AllChainsDocument,
  '\n  fragment UserItem on Users {\n    id\n    fullName\n    email\n    lastSeen\n    phoneNumber\n    publicAddress\n    type\n    defaultAccountId\n    createdAt\n    countryId\n    city\n    avatarURI\n    address\n    updatedAt\n  }\n':
    types.UserItemFragmentDoc,
  '\n  query GetUser($id: String = "") {\n    user: usersByPk(id: $id) {\n      ...UserItem\n    }\n  }\n':
    types.GetUserDocument,
  'fragment AccountItem on Accounts {\n  id\n  name\n  logoURI\n  ownerId\n  supportEmail\n  supportPhone\n  type\n  tz\n  defaultCurrencyId\n  customDomain\n  createdAt\n  countryId\n  businessUrl\n  businessName\n  updatedAt\n}\n\nfragment AccountMemberItem on AccountMembers {\n  id\n  accountId\n  type\n  userId\n}':
    types.AccountItemFragmentDoc,
  '\n  mutation createNewAccount($object: AccountsInsertInput = {}) {\n    insertAccountsOne(object: $object) {\n      id\n      logoURI\n      name\n    }\n  }\n':
    types.CreateNewAccountDocument,
  'query UserAccounts($userId: String = "") {\n  user: usersByPk(id: $userId) {\n    id\n    defaultAccountId\n    ownerAccounts: accounts {\n      ...AccountItem\n    }\n    memberAccounts: accountMembers {\n      ...AccountMemberItem\n      account {\n        ...AccountItem\n      }\n    }\n  }\n}':
    types.UserAccountsDocument,
  'mutation connectWallet($message: String = "", $signature: String = "") {\n  connectWallet(message: $message, signature: $signature) {\n    customToken\n  }\n}':
    types.ConnectWalletDocument,
  'mutation Initial($avatarURI: String) {\n  initial(avatarURI: $avatarURI) {\n    user {\n      id\n      defaultAccountId\n      ownerAccounts: accounts {\n        ...AccountItem\n      }\n      memberAccounts: accountMembers {\n        ...AccountMemberItem\n        account {\n          ...AccountItem\n        }\n      }\n    }\n  }\n}':
    types.InitialDocument,
  '\n  mutation updateAccount($id: String = "", $_set: AccountsSetInput = {}) {\n    account: updateAccountsByPk(pkColumns: {id: $id}, _set: $_set) {\n      ...AccountItem\n    }\n  }\n':
    types.UpdateAccountDocument,
  '\n  mutation updatePaymentMethod($id: String = "", $_set: PaymentMethodsSetInput = {}) {\n    updatePaymentMethodsByPk(pkColumns: {id: $id}, _set: $_set) {\n      ...PaymentMethodItem\n      targetChains {\n        ...PaymentMethodChainItem\n        supportedCoins {\n          ...CoinItem\n        }\n      }\n    }\n  }\n':
    types.UpdatePaymentMethodDocument,
  'fragment CoinItem on Coins {\n  id\n  logoURI\n  name\n  symbol\n}\n\nfragment PaymentMethodChainItem on PaymentMethodChains {\n  id\n  chainId\n  paymentMethodId\n  publicAddress\n  supportedCoinIds\n}\n\nfragment PaymentMethodItem on PaymentMethods {\n  id\n  accountId\n  isActive\n  isDefault\n  name\n  createdAt\n  updatedAt\n}':
    types.CoinItemFragmentDoc,
  '\n  query listPaymentMethods(\n    $limit: Int = 20\n    $offset: Int = 0\n    $where: PaymentMethodsBoolExp = {}\n  ) {\n    paymentMethodsAggregate(where: $where) {\n      aggregate {\n        count\n      }\n    }\n    paymentMethods(\n      limit: $limit\n      offset: $offset\n      where: $where\n      orderBy: {isDefault: DESC, isActive: DESC}\n    ) {\n      ...PaymentMethodItem\n      targetChains {\n        ...PaymentMethodChainItem\n        supportedCoins {\n          ...CoinItem\n        }\n      }\n    }\n  }\n':
    types.ListPaymentMethodsDocument,
  'mutation updatePaymentMethod($id: String = "", $_set: PaymentMethodsSetInput = {}) {\n  updatePaymentMethodsByPk(pkColumns: {id: $id}, _set: $_set) {\n    ...PaymentMethodItem\n    targetChains {\n      ...PaymentMethodChainItem\n      supportedCoins {\n        ...CoinItem\n      }\n    }\n  }\n}':
    types.UpdatePaymentMethodDocument,
  'query listPaymentMethods($limit: Int = 20, $offset: Int = 0, $where: PaymentMethodsBoolExp = {}) {\n  paymentMethodsAggregate(where: $where) {\n    aggregate {\n      count\n    }\n  }\n  paymentMethods(\n    limit: $limit\n    offset: $offset\n    where: $where\n    orderBy: {isDefault: DESC, isActive: DESC}\n  ) {\n    ...PaymentMethodItem\n    targetChains {\n      ...PaymentMethodChainItem\n      supportedCoins {\n        ...CoinItem\n      }\n    }\n  }\n}':
    types.ListPaymentMethodsDocument,
  '\n  mutation updateUserProfile($id: String = "", $_set: UsersSetInput = {}) {\n    user: updateUsersByPk(pkColumns: {id: $id}, _set: $_set) {\n      ...UserItem\n    }\n  }\n':
    types.UpdateUserProfileDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation Initial($avatarURI: String) {\n    initial(avatarURI: $avatarURI) {\n      user {\n        id\n        defaultAccountId\n        ownerAccounts: accounts {\n          ...AccountItem\n        }\n        memberAccounts: accountMembers {\n          ...AccountMemberItem\n          account {\n            ...AccountItem\n          }\n        }\n      }\n    }\n  }\n'
): typeof import('./graphql').InitialDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query UserAccounts($userId: String = "") {\n    user: usersByPk(id: $userId) {\n      id\n      defaultAccountId\n      ownerAccounts: accounts {\n        ...AccountItem\n      }\n      memberAccounts: accountMembers {\n        ...AccountMemberItem\n        account {\n          ...AccountItem\n        }\n      }\n    }\n  }\n'
): typeof import('./graphql').UserAccountsDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getCoin($id: String = "") {\n    coinsByPk(id: $id) {\n      ...CoinItem\n    }\n  }\n'
): typeof import('./graphql').GetCoinDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment CountryItem on Countries {\n    id\n    dialCode\n    emoji\n    name\n  }\n'
): typeof import('./graphql').CountryItemFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetCountries {\n    countries {\n      ...CountryItem\n    }\n  }\n'
): typeof import('./graphql').GetCountriesDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment CurrencyItem on Currencies {\n    id\n    name\n    rate\n    symbol\n    logoURI\n    updatedAt\n  }\n'
): typeof import('./graphql').CurrencyItemFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetCurrencies @cached {\n    currencies {\n      ...CurrencyItem\n    }\n  }\n'
): typeof import('./graphql').GetCurrenciesDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ChainItem on Chains {\n    id\n    name\n    isTestnet\n    logoURI\n    coinId\n  }\n'
): typeof import('./graphql').ChainItemFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query allChains {\n    chains: chains(orderBy: {isTestnet: ASC}) {\n      ...ChainItem\n    }\n  }\n'
): typeof import('./graphql').AllChainsDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UserItem on Users {\n    id\n    fullName\n    email\n    lastSeen\n    phoneNumber\n    publicAddress\n    type\n    defaultAccountId\n    createdAt\n    countryId\n    city\n    avatarURI\n    address\n    updatedAt\n  }\n'
): typeof import('./graphql').UserItemFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetUser($id: String = "") {\n    user: usersByPk(id: $id) {\n      ...UserItem\n    }\n  }\n'
): typeof import('./graphql').GetUserDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: 'fragment AccountItem on Accounts {\n  id\n  name\n  logoURI\n  ownerId\n  supportEmail\n  supportPhone\n  type\n  tz\n  defaultCurrencyId\n  customDomain\n  createdAt\n  countryId\n  businessUrl\n  businessName\n  updatedAt\n}\n\nfragment AccountMemberItem on AccountMembers {\n  id\n  accountId\n  type\n  userId\n}'
): typeof import('./graphql').AccountItemFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createNewAccount($object: AccountsInsertInput = {}) {\n    insertAccountsOne(object: $object) {\n      id\n      logoURI\n      name\n    }\n  }\n'
): typeof import('./graphql').CreateNewAccountDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: 'query UserAccounts($userId: String = "") {\n  user: usersByPk(id: $userId) {\n    id\n    defaultAccountId\n    ownerAccounts: accounts {\n      ...AccountItem\n    }\n    memberAccounts: accountMembers {\n      ...AccountMemberItem\n      account {\n        ...AccountItem\n      }\n    }\n  }\n}'
): typeof import('./graphql').UserAccountsDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: 'mutation connectWallet($message: String = "", $signature: String = "") {\n  connectWallet(message: $message, signature: $signature) {\n    customToken\n  }\n}'
): typeof import('./graphql').ConnectWalletDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: 'mutation Initial($avatarURI: String) {\n  initial(avatarURI: $avatarURI) {\n    user {\n      id\n      defaultAccountId\n      ownerAccounts: accounts {\n        ...AccountItem\n      }\n      memberAccounts: accountMembers {\n        ...AccountMemberItem\n        account {\n          ...AccountItem\n        }\n      }\n    }\n  }\n}'
): typeof import('./graphql').InitialDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateAccount($id: String = "", $_set: AccountsSetInput = {}) {\n    account: updateAccountsByPk(pkColumns: {id: $id}, _set: $_set) {\n      ...AccountItem\n    }\n  }\n'
): typeof import('./graphql').UpdateAccountDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updatePaymentMethod($id: String = "", $_set: PaymentMethodsSetInput = {}) {\n    updatePaymentMethodsByPk(pkColumns: {id: $id}, _set: $_set) {\n      ...PaymentMethodItem\n      targetChains {\n        ...PaymentMethodChainItem\n        supportedCoins {\n          ...CoinItem\n        }\n      }\n    }\n  }\n'
): typeof import('./graphql').UpdatePaymentMethodDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: 'fragment CoinItem on Coins {\n  id\n  logoURI\n  name\n  symbol\n}\n\nfragment PaymentMethodChainItem on PaymentMethodChains {\n  id\n  chainId\n  paymentMethodId\n  publicAddress\n  supportedCoinIds\n}\n\nfragment PaymentMethodItem on PaymentMethods {\n  id\n  accountId\n  isActive\n  isDefault\n  name\n  createdAt\n  updatedAt\n}'
): typeof import('./graphql').CoinItemFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query listPaymentMethods(\n    $limit: Int = 20\n    $offset: Int = 0\n    $where: PaymentMethodsBoolExp = {}\n  ) {\n    paymentMethodsAggregate(where: $where) {\n      aggregate {\n        count\n      }\n    }\n    paymentMethods(\n      limit: $limit\n      offset: $offset\n      where: $where\n      orderBy: {isDefault: DESC, isActive: DESC}\n    ) {\n      ...PaymentMethodItem\n      targetChains {\n        ...PaymentMethodChainItem\n        supportedCoins {\n          ...CoinItem\n        }\n      }\n    }\n  }\n'
): typeof import('./graphql').ListPaymentMethodsDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: 'mutation updatePaymentMethod($id: String = "", $_set: PaymentMethodsSetInput = {}) {\n  updatePaymentMethodsByPk(pkColumns: {id: $id}, _set: $_set) {\n    ...PaymentMethodItem\n    targetChains {\n      ...PaymentMethodChainItem\n      supportedCoins {\n        ...CoinItem\n      }\n    }\n  }\n}'
): typeof import('./graphql').UpdatePaymentMethodDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: 'query listPaymentMethods($limit: Int = 20, $offset: Int = 0, $where: PaymentMethodsBoolExp = {}) {\n  paymentMethodsAggregate(where: $where) {\n    aggregate {\n      count\n    }\n  }\n  paymentMethods(\n    limit: $limit\n    offset: $offset\n    where: $where\n    orderBy: {isDefault: DESC, isActive: DESC}\n  ) {\n    ...PaymentMethodItem\n    targetChains {\n      ...PaymentMethodChainItem\n      supportedCoins {\n        ...CoinItem\n      }\n    }\n  }\n}'
): typeof import('./graphql').ListPaymentMethodsDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateUserProfile($id: String = "", $_set: UsersSetInput = {}) {\n    user: updateUsersByPk(pkColumns: {id: $id}, _set: $_set) {\n      ...UserItem\n    }\n  }\n'
): typeof import('./graphql').UpdateUserProfileDocument;

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}
