import RouteLoader from 'components/RouteLoader';
import {lazy} from 'react';

export const lazyRouter = (
  factory: () => Promise<{
    default: () => JSX.Element;
  }>
) => {
  const LazyComponent = lazy(factory);
  return (
    <RouteLoader>
      <LazyComponent />
    </RouteLoader>
  );
};
