import {HStack, useBreakpointValue} from '@chakra-ui/react';
import Logo from 'components/Logo';
import NavUser from 'components/NavbarLayout/NavUser';
import ToggleColorModeButton from 'components/ToggleColorModeButton';
import useAccount from 'hooks/useAccount';

const Header = () => {
  const isDesktop = useBreakpointValue({base: false, lg: true});
  const {isLogin} = useAccount();
  return (
    <HStack h='12' justify={'space-between'} p={{base: 2, md: 4}} position='sticky'>
      <Logo h='6' onlyIcon />
      <HStack>
        <ToggleColorModeButton />
        {isLogin && <NavUser isDesktop={isDesktop} />}
      </HStack>
    </HStack>
  );
};

export default Header;
