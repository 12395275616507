/* eslint-disable */
import {DocumentTypeDecoration} from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _text: any;
  numeric: any;
  timestamptz: any;
  uuid: any;
};

/** columns and relationships of "AccountMemberTypes" */
export type AccountMemberTypes = {
  __typename?: 'AccountMemberTypes';
  id: Scalars['String'];
};

/** Boolean expression to filter rows from the table "AccountMemberTypes". All fields are combined with a logical 'AND'. */
export type AccountMemberTypesBoolExp = {
  _and?: InputMaybe<Array<AccountMemberTypesBoolExp>>;
  _not?: InputMaybe<AccountMemberTypesBoolExp>;
  _or?: InputMaybe<Array<AccountMemberTypesBoolExp>>;
  id?: InputMaybe<StringComparisonExp>;
};

export enum AccountMemberTypesEnum {
  Admin = 'ADMIN',
  Member = 'MEMBER',
  Owner = 'OWNER',
}

/** Boolean expression to compare columns of type "AccountMemberTypesEnum". All fields are combined with logical 'AND'. */
export type AccountMemberTypesEnumComparisonExp = {
  _eq?: InputMaybe<AccountMemberTypesEnum>;
  _in?: InputMaybe<Array<AccountMemberTypesEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<AccountMemberTypesEnum>;
  _nin?: InputMaybe<Array<AccountMemberTypesEnum>>;
};

/** Ordering options when selecting data from "AccountMemberTypes". */
export type AccountMemberTypesOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** select columns of table "AccountMemberTypes" */
export enum AccountMemberTypesSelectColumn {
  /** column name */
  Id = 'id',
}

/** Streaming cursor of the table "AccountMemberTypes" */
export type AccountMemberTypesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AccountMemberTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AccountMemberTypesStreamCursorValueInput = {
  id?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "AccountMembers" */
export type AccountMembers = {
  __typename?: 'AccountMembers';
  /** An object relationship */
  account: Accounts;
  accountId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  isAccepted: Scalars['Boolean'];
  type: AccountMemberTypesEnum;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  userId: Scalars['String'];
};

/** aggregated selection of "AccountMembers" */
export type AccountMembersAggregate = {
  __typename?: 'AccountMembersAggregate';
  aggregate?: Maybe<AccountMembersAggregateFields>;
  nodes: Array<AccountMembers>;
};

export type AccountMembersAggregateBoolExp = {
  bool_and?: InputMaybe<AccountMembersAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<AccountMembersAggregateBoolExpBool_Or>;
  count?: InputMaybe<AccountMembersAggregateBoolExpCount>;
};

/** aggregate fields of "AccountMembers" */
export type AccountMembersAggregateFields = {
  __typename?: 'AccountMembersAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<AccountMembersMaxFields>;
  min?: Maybe<AccountMembersMinFields>;
};

/** aggregate fields of "AccountMembers" */
export type AccountMembersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AccountMembersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "AccountMembers" */
export type AccountMembersAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AccountMembersMaxOrderBy>;
  min?: InputMaybe<AccountMembersMinOrderBy>;
};

/** Boolean expression to filter rows from the table "AccountMembers". All fields are combined with a logical 'AND'. */
export type AccountMembersBoolExp = {
  _and?: InputMaybe<Array<AccountMembersBoolExp>>;
  _not?: InputMaybe<AccountMembersBoolExp>;
  _or?: InputMaybe<Array<AccountMembersBoolExp>>;
  account?: InputMaybe<AccountsBoolExp>;
  accountId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isAccepted?: InputMaybe<BooleanComparisonExp>;
  type?: InputMaybe<AccountMemberTypesEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type AccountMembersMaxFields = {
  __typename?: 'AccountMembersMaxFields';
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "AccountMembers" */
export type AccountMembersMaxOrderBy = {
  accountId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AccountMembersMinFields = {
  __typename?: 'AccountMembersMinFields';
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "AccountMembers" */
export type AccountMembersMinOrderBy = {
  accountId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "AccountMembers" */
export type AccountMembersMutationResponse = {
  __typename?: 'AccountMembersMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AccountMembers>;
};

/** Ordering options when selecting data from "AccountMembers". */
export type AccountMembersOrderBy = {
  account?: InputMaybe<AccountsOrderBy>;
  accountId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isAccepted?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: AccountMembers */
export type AccountMembersPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "AccountMembers" */
export enum AccountMembersSelectColumn {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsAccepted = 'isAccepted',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

/** select "accountMembersAggregateBoolExpBool_andArgumentsColumns" columns of table "AccountMembers" */
export enum AccountMembersSelectColumnAccountMembersAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsAccepted = 'isAccepted',
}

/** select "accountMembersAggregateBoolExpBool_orArgumentsColumns" columns of table "AccountMembers" */
export enum AccountMembersSelectColumnAccountMembersAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsAccepted = 'isAccepted',
}

/** input type for updating data in table "AccountMembers" */
export type AccountMembersSetInput = {
  type?: InputMaybe<AccountMemberTypesEnum>;
};

export type AccountMembersUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AccountMembersSetInput>;
  /** filter the rows which have to be updated */
  where: AccountMembersBoolExp;
};

/** columns and relationships of "Accounts" */
export type Accounts = {
  __typename?: 'Accounts';
  /** An array relationship */
  accountMembers: Array<AccountMembers>;
  /** An aggregate relationship */
  accountMembersAggregate: AccountMembersAggregate;
  businessName?: Maybe<Scalars['String']>;
  businessUrl?: Maybe<Scalars['String']>;
  /** An object relationship */
  country?: Maybe<Countries>;
  countryId?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  customDomain?: Maybe<Scalars['String']>;
  /** An array relationship */
  customers: Array<Customers>;
  /** An object relationship */
  defaultCurrency: Currencies;
  defaultCurrencyId: Scalars['String'];
  /** An array relationship */
  defaultPaymentMethod: Array<PaymentMethods>;
  /** An aggregate relationship */
  defaultPaymentMethodAggregate: PaymentMethodsAggregate;
  id: Scalars['String'];
  logoURI?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An object relationship */
  owner: Users;
  ownerId: Scalars['String'];
  supportEmail?: Maybe<Scalars['String']>;
  supportPhone?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  tz: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "Accounts" */
export type AccountsAccountMembersArgs = {
  distinctOn?: InputMaybe<Array<AccountMembersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountMembersOrderBy>>;
  where?: InputMaybe<AccountMembersBoolExp>;
};

/** columns and relationships of "Accounts" */
export type AccountsAccountMembersAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccountMembersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountMembersOrderBy>>;
  where?: InputMaybe<AccountMembersBoolExp>;
};

/** columns and relationships of "Accounts" */
export type AccountsCustomersArgs = {
  distinctOn?: InputMaybe<Array<CustomersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CustomersOrderBy>>;
  where?: InputMaybe<CustomersBoolExp>;
};

/** columns and relationships of "Accounts" */
export type AccountsDefaultPaymentMethodArgs = {
  distinctOn?: InputMaybe<Array<PaymentMethodsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentMethodsOrderBy>>;
  where?: InputMaybe<PaymentMethodsBoolExp>;
};

/** columns and relationships of "Accounts" */
export type AccountsDefaultPaymentMethodAggregateArgs = {
  distinctOn?: InputMaybe<Array<PaymentMethodsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentMethodsOrderBy>>;
  where?: InputMaybe<PaymentMethodsBoolExp>;
};

/** aggregated selection of "Accounts" */
export type AccountsAggregate = {
  __typename?: 'AccountsAggregate';
  aggregate?: Maybe<AccountsAggregateFields>;
  nodes: Array<Accounts>;
};

export type AccountsAggregateBoolExp = {
  count?: InputMaybe<AccountsAggregateBoolExpCount>;
};

/** aggregate fields of "Accounts" */
export type AccountsAggregateFields = {
  __typename?: 'AccountsAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<AccountsMaxFields>;
  min?: Maybe<AccountsMinFields>;
};

/** aggregate fields of "Accounts" */
export type AccountsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AccountsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Accounts" */
export type AccountsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AccountsMaxOrderBy>;
  min?: InputMaybe<AccountsMinOrderBy>;
};

/** Boolean expression to filter rows from the table "Accounts". All fields are combined with a logical 'AND'. */
export type AccountsBoolExp = {
  _and?: InputMaybe<Array<AccountsBoolExp>>;
  _not?: InputMaybe<AccountsBoolExp>;
  _or?: InputMaybe<Array<AccountsBoolExp>>;
  accountMembers?: InputMaybe<AccountMembersBoolExp>;
  accountMembersAggregate?: InputMaybe<AccountMembersAggregateBoolExp>;
  businessName?: InputMaybe<StringComparisonExp>;
  businessUrl?: InputMaybe<StringComparisonExp>;
  country?: InputMaybe<CountriesBoolExp>;
  countryId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  customDomain?: InputMaybe<StringComparisonExp>;
  customers?: InputMaybe<CustomersBoolExp>;
  defaultCurrency?: InputMaybe<CurrenciesBoolExp>;
  defaultCurrencyId?: InputMaybe<StringComparisonExp>;
  defaultPaymentMethod?: InputMaybe<PaymentMethodsBoolExp>;
  defaultPaymentMethodAggregate?: InputMaybe<PaymentMethodsAggregateBoolExp>;
  id?: InputMaybe<StringComparisonExp>;
  logoURI?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  owner?: InputMaybe<UsersBoolExp>;
  ownerId?: InputMaybe<StringComparisonExp>;
  supportEmail?: InputMaybe<StringComparisonExp>;
  supportPhone?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
  tz?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "Accounts" */
export enum AccountsConstraint {
  /** unique or primary key constraint on columns "id" */
  AccountsPkey = 'Accounts_pkey',
}

/** input type for inserting data into table "Accounts" */
export type AccountsInsertInput = {
  businessName?: InputMaybe<Scalars['String']>;
  businessUrl?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['String']>;
  customers?: InputMaybe<CustomersArrRelInsertInput>;
  defaultCurrencyId?: InputMaybe<Scalars['String']>;
  defaultPaymentMethod?: InputMaybe<PaymentMethodsArrRelInsertInput>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  tz?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type AccountsMaxFields = {
  __typename?: 'AccountsMaxFields';
  businessName?: Maybe<Scalars['String']>;
  businessUrl?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customDomain?: Maybe<Scalars['String']>;
  defaultCurrencyId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  logoURI?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  supportEmail?: Maybe<Scalars['String']>;
  supportPhone?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  tz?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Accounts" */
export type AccountsMaxOrderBy = {
  businessName?: InputMaybe<OrderBy>;
  businessUrl?: InputMaybe<OrderBy>;
  countryId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  customDomain?: InputMaybe<OrderBy>;
  defaultCurrencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  logoURI?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  ownerId?: InputMaybe<OrderBy>;
  supportEmail?: InputMaybe<OrderBy>;
  supportPhone?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  tz?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AccountsMinFields = {
  __typename?: 'AccountsMinFields';
  businessName?: Maybe<Scalars['String']>;
  businessUrl?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customDomain?: Maybe<Scalars['String']>;
  defaultCurrencyId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  logoURI?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  supportEmail?: Maybe<Scalars['String']>;
  supportPhone?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  tz?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Accounts" */
export type AccountsMinOrderBy = {
  businessName?: InputMaybe<OrderBy>;
  businessUrl?: InputMaybe<OrderBy>;
  countryId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  customDomain?: InputMaybe<OrderBy>;
  defaultCurrencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  logoURI?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  ownerId?: InputMaybe<OrderBy>;
  supportEmail?: InputMaybe<OrderBy>;
  supportPhone?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  tz?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "Accounts" */
export type AccountsMutationResponse = {
  __typename?: 'AccountsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Accounts>;
};

/** input type for inserting object relation for remote table "Accounts" */
export type AccountsObjRelInsertInput = {
  data: AccountsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<AccountsOnConflict>;
};

/** on_conflict condition type for table "Accounts" */
export type AccountsOnConflict = {
  constraint: AccountsConstraint;
  updateColumns?: Array<AccountsUpdateColumn>;
  where?: InputMaybe<AccountsBoolExp>;
};

/** Ordering options when selecting data from "Accounts". */
export type AccountsOrderBy = {
  accountMembersAggregate?: InputMaybe<AccountMembersAggregateOrderBy>;
  businessName?: InputMaybe<OrderBy>;
  businessUrl?: InputMaybe<OrderBy>;
  country?: InputMaybe<CountriesOrderBy>;
  countryId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  customDomain?: InputMaybe<OrderBy>;
  customersAggregate?: InputMaybe<CustomersAggregateOrderBy>;
  defaultCurrency?: InputMaybe<CurrenciesOrderBy>;
  defaultCurrencyId?: InputMaybe<OrderBy>;
  defaultPaymentMethodAggregate?: InputMaybe<PaymentMethodsAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  logoURI?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  owner?: InputMaybe<UsersOrderBy>;
  ownerId?: InputMaybe<OrderBy>;
  supportEmail?: InputMaybe<OrderBy>;
  supportPhone?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  tz?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: Accounts */
export type AccountsPkColumnsInput = {
  id: Scalars['String'];
};

/** select columns of table "Accounts" */
export enum AccountsSelectColumn {
  /** column name */
  BusinessName = 'businessName',
  /** column name */
  BusinessUrl = 'businessUrl',
  /** column name */
  CountryId = 'countryId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomDomain = 'customDomain',
  /** column name */
  DefaultCurrencyId = 'defaultCurrencyId',
  /** column name */
  Id = 'id',
  /** column name */
  LogoUri = 'logoURI',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  SupportEmail = 'supportEmail',
  /** column name */
  SupportPhone = 'supportPhone',
  /** column name */
  Type = 'type',
  /** column name */
  Tz = 'tz',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "Accounts" */
export type AccountsSetInput = {
  businessName?: InputMaybe<Scalars['String']>;
  businessUrl?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['String']>;
  customDomain?: InputMaybe<Scalars['String']>;
  defaultCurrencyId?: InputMaybe<Scalars['String']>;
  logoURI?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  supportEmail?: InputMaybe<Scalars['String']>;
  supportPhone?: InputMaybe<Scalars['String']>;
  tz?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "Accounts" */
export type AccountsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AccountsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AccountsStreamCursorValueInput = {
  businessName?: InputMaybe<Scalars['String']>;
  businessUrl?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customDomain?: InputMaybe<Scalars['String']>;
  defaultCurrencyId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  logoURI?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['String']>;
  supportEmail?: InputMaybe<Scalars['String']>;
  supportPhone?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  tz?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "Accounts" */
export enum AccountsUpdateColumn {
  /** column name */
  BusinessName = 'businessName',
  /** column name */
  BusinessUrl = 'businessUrl',
  /** column name */
  CountryId = 'countryId',
  /** column name */
  CustomDomain = 'customDomain',
  /** column name */
  DefaultCurrencyId = 'defaultCurrencyId',
  /** column name */
  LogoUri = 'logoURI',
  /** column name */
  Name = 'name',
  /** column name */
  SupportEmail = 'supportEmail',
  /** column name */
  SupportPhone = 'supportPhone',
  /** column name */
  Tz = 'tz',
}

export type AccountsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AccountsSetInput>;
  /** filter the rows which have to be updated */
  where: AccountsBoolExp;
};

/** columns and relationships of "AllowedCoins" */
export type AllowedCoins = {
  __typename?: 'AllowedCoins';
  /** An object relationship */
  coin: Coins;
  coinId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  isLive?: Maybe<Scalars['Boolean']>;
  /** based USD */
  rate?: Maybe<Scalars['numeric']>;
  updatedAt: Scalars['timestamptz'];
};

/** Boolean expression to filter rows from the table "AllowedCoins". All fields are combined with a logical 'AND'. */
export type AllowedCoinsBoolExp = {
  _and?: InputMaybe<Array<AllowedCoinsBoolExp>>;
  _not?: InputMaybe<AllowedCoinsBoolExp>;
  _or?: InputMaybe<Array<AllowedCoinsBoolExp>>;
  coin?: InputMaybe<CoinsBoolExp>;
  coinId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  isLive?: InputMaybe<BooleanComparisonExp>;
  rate?: InputMaybe<NumericComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** Ordering options when selecting data from "AllowedCoins". */
export type AllowedCoinsOrderBy = {
  coin?: InputMaybe<CoinsOrderBy>;
  coinId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  isLive?: InputMaybe<OrderBy>;
  rate?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** select columns of table "AllowedCoins" */
export enum AllowedCoinsSelectColumn {
  /** column name */
  CoinId = 'coinId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  IsLive = 'isLive',
  /** column name */
  Rate = 'rate',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** Streaming cursor of the table "AllowedCoins" */
export type AllowedCoinsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AllowedCoinsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AllowedCoinsStreamCursorValueInput = {
  coinId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  isLive?: InputMaybe<Scalars['Boolean']>;
  /** based USD */
  rate?: InputMaybe<Scalars['numeric']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** columns and relationships of "Chains" */
export type Chains = {
  __typename?: 'Chains';
  /** An object relationship */
  coin: Coins;
  coinId: Scalars['String'];
  id: Scalars['Int'];
  isTestnet: Scalars['Boolean'];
  logoURI?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An array relationship */
  tokenContracts: Array<TokenContracts>;
  /** An aggregate relationship */
  tokenContractsAggregate: TokenContractsAggregate;
};

/** columns and relationships of "Chains" */
export type ChainsTokenContractsArgs = {
  distinctOn?: InputMaybe<Array<TokenContractsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TokenContractsOrderBy>>;
  where?: InputMaybe<TokenContractsBoolExp>;
};

/** columns and relationships of "Chains" */
export type ChainsTokenContractsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TokenContractsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TokenContractsOrderBy>>;
  where?: InputMaybe<TokenContractsBoolExp>;
};

/** order by aggregate values of table "Chains" */
export type ChainsAggregateOrderBy = {
  avg?: InputMaybe<ChainsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ChainsMaxOrderBy>;
  min?: InputMaybe<ChainsMinOrderBy>;
  stddev?: InputMaybe<ChainsStddevOrderBy>;
  stddevPop?: InputMaybe<ChainsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ChainsStddevSampOrderBy>;
  sum?: InputMaybe<ChainsSumOrderBy>;
  varPop?: InputMaybe<ChainsVarPopOrderBy>;
  varSamp?: InputMaybe<ChainsVarSampOrderBy>;
  variance?: InputMaybe<ChainsVarianceOrderBy>;
};

/** order by avg() on columns of table "Chains" */
export type ChainsAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "Chains". All fields are combined with a logical 'AND'. */
export type ChainsBoolExp = {
  _and?: InputMaybe<Array<ChainsBoolExp>>;
  _not?: InputMaybe<ChainsBoolExp>;
  _or?: InputMaybe<Array<ChainsBoolExp>>;
  coin?: InputMaybe<CoinsBoolExp>;
  coinId?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  isTestnet?: InputMaybe<BooleanComparisonExp>;
  logoURI?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  tokenContracts?: InputMaybe<TokenContractsBoolExp>;
  tokenContractsAggregate?: InputMaybe<TokenContractsAggregateBoolExp>;
};

/** order by max() on columns of table "Chains" */
export type ChainsMaxOrderBy = {
  coinId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  logoURI?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "Chains" */
export type ChainsMinOrderBy = {
  coinId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  logoURI?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "Chains". */
export type ChainsOrderBy = {
  coin?: InputMaybe<CoinsOrderBy>;
  coinId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isTestnet?: InputMaybe<OrderBy>;
  logoURI?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  tokenContractsAggregate?: InputMaybe<TokenContractsAggregateOrderBy>;
};

/** select columns of table "Chains" */
export enum ChainsSelectColumn {
  /** column name */
  CoinId = 'coinId',
  /** column name */
  Id = 'id',
  /** column name */
  IsTestnet = 'isTestnet',
  /** column name */
  LogoUri = 'logoURI',
  /** column name */
  Name = 'name',
}

/** order by stddev() on columns of table "Chains" */
export type ChainsStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "Chains" */
export type ChainsStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "Chains" */
export type ChainsStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** order by sum() on columns of table "Chains" */
export type ChainsSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "Chains" */
export type ChainsVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "Chains" */
export type ChainsVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "Chains" */
export type ChainsVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "Coins" */
export type Coins = {
  __typename?: 'Coins';
  /** An array relationship */
  chains: Array<Chains>;
  id: Scalars['String'];
  logoURI?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  symbol: Scalars['String'];
  /** An array relationship */
  tokenContracts: Array<TokenContracts>;
  /** An aggregate relationship */
  tokenContractsAggregate: TokenContractsAggregate;
};

/** columns and relationships of "Coins" */
export type CoinsChainsArgs = {
  distinctOn?: InputMaybe<Array<ChainsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ChainsOrderBy>>;
  where?: InputMaybe<ChainsBoolExp>;
};

/** columns and relationships of "Coins" */
export type CoinsTokenContractsArgs = {
  distinctOn?: InputMaybe<Array<TokenContractsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TokenContractsOrderBy>>;
  where?: InputMaybe<TokenContractsBoolExp>;
};

/** columns and relationships of "Coins" */
export type CoinsTokenContractsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TokenContractsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TokenContractsOrderBy>>;
  where?: InputMaybe<TokenContractsBoolExp>;
};

/** aggregated selection of "Coins" */
export type CoinsAggregate = {
  __typename?: 'CoinsAggregate';
  aggregate?: Maybe<CoinsAggregateFields>;
  nodes: Array<Coins>;
};

/** aggregate fields of "Coins" */
export type CoinsAggregateFields = {
  __typename?: 'CoinsAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<CoinsMaxFields>;
  min?: Maybe<CoinsMinFields>;
};

/** aggregate fields of "Coins" */
export type CoinsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CoinsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Coins" */
export type CoinsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CoinsMaxOrderBy>;
  min?: InputMaybe<CoinsMinOrderBy>;
};

/** Boolean expression to filter rows from the table "Coins". All fields are combined with a logical 'AND'. */
export type CoinsBoolExp = {
  _and?: InputMaybe<Array<CoinsBoolExp>>;
  _not?: InputMaybe<CoinsBoolExp>;
  _or?: InputMaybe<Array<CoinsBoolExp>>;
  chains?: InputMaybe<ChainsBoolExp>;
  id?: InputMaybe<StringComparisonExp>;
  logoURI?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  symbol?: InputMaybe<StringComparisonExp>;
  tokenContracts?: InputMaybe<TokenContractsBoolExp>;
  tokenContractsAggregate?: InputMaybe<TokenContractsAggregateBoolExp>;
};

/** aggregate max on columns */
export type CoinsMaxFields = {
  __typename?: 'CoinsMaxFields';
  id?: Maybe<Scalars['String']>;
  logoURI?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Coins" */
export type CoinsMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  logoURI?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  symbol?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CoinsMinFields = {
  __typename?: 'CoinsMinFields';
  id?: Maybe<Scalars['String']>;
  logoURI?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Coins" */
export type CoinsMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  logoURI?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  symbol?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "Coins". */
export type CoinsOrderBy = {
  chainsAggregate?: InputMaybe<ChainsAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  logoURI?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  symbol?: InputMaybe<OrderBy>;
  tokenContractsAggregate?: InputMaybe<TokenContractsAggregateOrderBy>;
};

/** select columns of table "Coins" */
export enum CoinsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  LogoUri = 'logoURI',
  /** column name */
  Name = 'name',
  /** column name */
  Symbol = 'symbol',
}

export type ConnectWalletOutput = {
  __typename?: 'ConnectWalletOutput';
  customToken: Scalars['String'];
};

/** columns and relationships of "Countries" */
export type Countries = {
  __typename?: 'Countries';
  dialCode: Scalars['String'];
  emoji: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "Countries". All fields are combined with a logical 'AND'. */
export type CountriesBoolExp = {
  _and?: InputMaybe<Array<CountriesBoolExp>>;
  _not?: InputMaybe<CountriesBoolExp>;
  _or?: InputMaybe<Array<CountriesBoolExp>>;
  dialCode?: InputMaybe<StringComparisonExp>;
  emoji?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "Countries". */
export type CountriesOrderBy = {
  dialCode?: InputMaybe<OrderBy>;
  emoji?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "Countries" */
export enum CountriesSelectColumn {
  /** column name */
  DialCode = 'dialCode',
  /** column name */
  Emoji = 'emoji',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** columns and relationships of "Currencies" */
export type Currencies = {
  __typename?: 'Currencies';
  createdAt: Scalars['timestamptz'];
  id: Scalars['String'];
  logoURI?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** based USD */
  rate?: Maybe<Scalars['numeric']>;
  symbol: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** Boolean expression to filter rows from the table "Currencies". All fields are combined with a logical 'AND'. */
export type CurrenciesBoolExp = {
  _and?: InputMaybe<Array<CurrenciesBoolExp>>;
  _not?: InputMaybe<CurrenciesBoolExp>;
  _or?: InputMaybe<Array<CurrenciesBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  logoURI?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  rate?: InputMaybe<NumericComparisonExp>;
  symbol?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** Ordering options when selecting data from "Currencies". */
export type CurrenciesOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  logoURI?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  rate?: InputMaybe<OrderBy>;
  symbol?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** select columns of table "Currencies" */
export enum CurrenciesSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LogoUri = 'logoURI',
  /** column name */
  Name = 'name',
  /** column name */
  Rate = 'rate',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** Streaming cursor of the table "Currencies" */
export type CurrenciesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CurrenciesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CurrenciesStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  logoURI?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  /** based USD */
  rate?: InputMaybe<Scalars['numeric']>;
  symbol?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** ordering argument of a cursor */
export enum CursorOrdering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

/** columns and relationships of "Customers" */
export type Customers = {
  __typename?: 'Customers';
  /** An object relationship */
  account: Accounts;
  accountId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};

/** order by aggregate values of table "Customers" */
export type CustomersAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CustomersMaxOrderBy>;
  min?: InputMaybe<CustomersMinOrderBy>;
};

/** input type for inserting array relation for remote table "Customers" */
export type CustomersArrRelInsertInput = {
  data: Array<CustomersInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<CustomersOnConflict>;
};

/** Boolean expression to filter rows from the table "Customers". All fields are combined with a logical 'AND'. */
export type CustomersBoolExp = {
  _and?: InputMaybe<Array<CustomersBoolExp>>;
  _not?: InputMaybe<CustomersBoolExp>;
  _or?: InputMaybe<Array<CustomersBoolExp>>;
  account?: InputMaybe<AccountsBoolExp>;
  accountId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  phone?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "Customers" */
export enum CustomersConstraint {
  /** unique or primary key constraint on columns "email", "accountId" */
  CustomersAccountIdEmailKey = 'Customers_accountId_email_key',
  /** unique or primary key constraint on columns "id" */
  CustomersPkey = 'Customers_pkey',
}

/** input type for inserting data into table "Customers" */
export type CustomersInsertInput = {
  account?: InputMaybe<AccountsObjRelInsertInput>;
  accountId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

/** order by max() on columns of table "Customers" */
export type CustomersMaxOrderBy = {
  accountId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "Customers" */
export type CustomersMinOrderBy = {
  accountId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "Customers" */
export type CustomersMutationResponse = {
  __typename?: 'CustomersMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customers>;
};

/** on_conflict condition type for table "Customers" */
export type CustomersOnConflict = {
  constraint: CustomersConstraint;
  updateColumns?: Array<CustomersUpdateColumn>;
  where?: InputMaybe<CustomersBoolExp>;
};

/** Ordering options when selecting data from "Customers". */
export type CustomersOrderBy = {
  account?: InputMaybe<AccountsOrderBy>;
  accountId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: Customers */
export type CustomersPkColumnsInput = {
  id: Scalars['String'];
};

/** select columns of table "Customers" */
export enum CustomersSelectColumn {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "Customers" */
export type CustomersSetInput = {
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "Customers" */
export type CustomersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CustomersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CustomersStreamCursorValueInput = {
  accountId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "Customers" */
export enum CustomersUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
}

export type CustomersUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CustomersSetInput>;
  /** filter the rows which have to be updated */
  where: CustomersBoolExp;
};

export type InitialOutput = {
  __typename?: 'InitialOutput';
  user?: Maybe<Users>;
  userId: Scalars['String'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type NumericComparisonExp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  Asc = 'ASC',
  /** in ascending order, nulls first */
  AscNullsFirst = 'ASC_NULLS_FIRST',
  /** in ascending order, nulls last */
  AscNullsLast = 'ASC_NULLS_LAST',
  /** in descending order, nulls first */
  Desc = 'DESC',
  /** in descending order, nulls first */
  DescNullsFirst = 'DESC_NULLS_FIRST',
  /** in descending order, nulls last */
  DescNullsLast = 'DESC_NULLS_LAST',
}

/** columns and relationships of "PaymentMethodChains" */
export type PaymentMethodChains = {
  __typename?: 'PaymentMethodChains';
  /** An object relationship */
  chain: Chains;
  chainId: Scalars['Int'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['String'];
  /** An object relationship */
  paymentMethod: PaymentMethods;
  paymentMethodId: Scalars['String'];
  publicAddress: Scalars['String'];
  supportedCoinIds: Scalars['_text'];
  /** A computed field, executes function "paymentmethodchains_supportedcoins" */
  supportedCoins?: Maybe<Array<Coins>>;
  /** A computed field, executes function "paymentmethodchains_supportedtokencontracts" */
  supportedTokenContracts?: Maybe<Array<TokenContracts>>;
};

/** columns and relationships of "PaymentMethodChains" */
export type PaymentMethodChainsSupportedCoinsArgs = {
  distinctOn?: InputMaybe<Array<CoinsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoinsOrderBy>>;
  where?: InputMaybe<CoinsBoolExp>;
};

/** columns and relationships of "PaymentMethodChains" */
export type PaymentMethodChainsSupportedTokenContractsArgs = {
  distinctOn?: InputMaybe<Array<TokenContractsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TokenContractsOrderBy>>;
  where?: InputMaybe<TokenContractsBoolExp>;
};

/** order by aggregate values of table "PaymentMethodChains" */
export type PaymentMethodChainsAggregateOrderBy = {
  avg?: InputMaybe<PaymentMethodChainsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PaymentMethodChainsMaxOrderBy>;
  min?: InputMaybe<PaymentMethodChainsMinOrderBy>;
  stddev?: InputMaybe<PaymentMethodChainsStddevOrderBy>;
  stddevPop?: InputMaybe<PaymentMethodChainsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<PaymentMethodChainsStddevSampOrderBy>;
  sum?: InputMaybe<PaymentMethodChainsSumOrderBy>;
  varPop?: InputMaybe<PaymentMethodChainsVarPopOrderBy>;
  varSamp?: InputMaybe<PaymentMethodChainsVarSampOrderBy>;
  variance?: InputMaybe<PaymentMethodChainsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "PaymentMethodChains" */
export type PaymentMethodChainsArrRelInsertInput = {
  data: Array<PaymentMethodChainsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<PaymentMethodChainsOnConflict>;
};

/** order by avg() on columns of table "PaymentMethodChains" */
export type PaymentMethodChainsAvgOrderBy = {
  chainId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "PaymentMethodChains". All fields are combined with a logical 'AND'. */
export type PaymentMethodChainsBoolExp = {
  _and?: InputMaybe<Array<PaymentMethodChainsBoolExp>>;
  _not?: InputMaybe<PaymentMethodChainsBoolExp>;
  _or?: InputMaybe<Array<PaymentMethodChainsBoolExp>>;
  chain?: InputMaybe<ChainsBoolExp>;
  chainId?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  paymentMethod?: InputMaybe<PaymentMethodsBoolExp>;
  paymentMethodId?: InputMaybe<StringComparisonExp>;
  publicAddress?: InputMaybe<StringComparisonExp>;
  supportedCoinIds?: InputMaybe<_TextComparisonExp>;
  supportedCoins?: InputMaybe<CoinsBoolExp>;
  supportedTokenContracts?: InputMaybe<TokenContractsBoolExp>;
};

/** unique or primary key constraints on table "PaymentMethodChains" */
export enum PaymentMethodChainsConstraint {
  /** unique or primary key constraint on columns "id" */
  PaymentMethodChainsPkey = 'PaymentMethodChains_pkey',
  /** unique or primary key constraint on columns "paymentMethodId", "chainId" */
  PaymentMethodIdChainIdUniqueIdx = 'paymentMethodId_chainId_unique_idx',
}

/** input type for inserting data into table "PaymentMethodChains" */
export type PaymentMethodChainsInsertInput = {
  chainId?: InputMaybe<Scalars['Int']>;
  paymentMethod?: InputMaybe<PaymentMethodsObjRelInsertInput>;
  paymentMethodId?: InputMaybe<Scalars['String']>;
  publicAddress?: InputMaybe<Scalars['String']>;
  supportedCoinIds?: InputMaybe<Scalars['_text']>;
};

/** order by max() on columns of table "PaymentMethodChains" */
export type PaymentMethodChainsMaxOrderBy = {
  chainId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  paymentMethodId?: InputMaybe<OrderBy>;
  publicAddress?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "PaymentMethodChains" */
export type PaymentMethodChainsMinOrderBy = {
  chainId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  paymentMethodId?: InputMaybe<OrderBy>;
  publicAddress?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "PaymentMethodChains" */
export type PaymentMethodChainsMutationResponse = {
  __typename?: 'PaymentMethodChainsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PaymentMethodChains>;
};

/** on_conflict condition type for table "PaymentMethodChains" */
export type PaymentMethodChainsOnConflict = {
  constraint: PaymentMethodChainsConstraint;
  updateColumns?: Array<PaymentMethodChainsUpdateColumn>;
  where?: InputMaybe<PaymentMethodChainsBoolExp>;
};

/** Ordering options when selecting data from "PaymentMethodChains". */
export type PaymentMethodChainsOrderBy = {
  chain?: InputMaybe<ChainsOrderBy>;
  chainId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  paymentMethod?: InputMaybe<PaymentMethodsOrderBy>;
  paymentMethodId?: InputMaybe<OrderBy>;
  publicAddress?: InputMaybe<OrderBy>;
  supportedCoinIds?: InputMaybe<OrderBy>;
  supportedCoinsAggregate?: InputMaybe<CoinsAggregateOrderBy>;
  supportedTokenContractsAggregate?: InputMaybe<TokenContractsAggregateOrderBy>;
};

/** select columns of table "PaymentMethodChains" */
export enum PaymentMethodChainsSelectColumn {
  /** column name */
  ChainId = 'chainId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentMethodId = 'paymentMethodId',
  /** column name */
  PublicAddress = 'publicAddress',
  /** column name */
  SupportedCoinIds = 'supportedCoinIds',
}

/** order by stddev() on columns of table "PaymentMethodChains" */
export type PaymentMethodChainsStddevOrderBy = {
  chainId?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "PaymentMethodChains" */
export type PaymentMethodChainsStddevPopOrderBy = {
  chainId?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "PaymentMethodChains" */
export type PaymentMethodChainsStddevSampOrderBy = {
  chainId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "PaymentMethodChains" */
export type PaymentMethodChainsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PaymentMethodChainsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PaymentMethodChainsStreamCursorValueInput = {
  chainId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  paymentMethodId?: InputMaybe<Scalars['String']>;
  publicAddress?: InputMaybe<Scalars['String']>;
  supportedCoinIds?: InputMaybe<Scalars['_text']>;
};

/** order by sum() on columns of table "PaymentMethodChains" */
export type PaymentMethodChainsSumOrderBy = {
  chainId?: InputMaybe<OrderBy>;
};

/** placeholder for update columns of table "PaymentMethodChains" (current role has no relevant permissions) */
export enum PaymentMethodChainsUpdateColumn {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** order by varPop() on columns of table "PaymentMethodChains" */
export type PaymentMethodChainsVarPopOrderBy = {
  chainId?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "PaymentMethodChains" */
export type PaymentMethodChainsVarSampOrderBy = {
  chainId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "PaymentMethodChains" */
export type PaymentMethodChainsVarianceOrderBy = {
  chainId?: InputMaybe<OrderBy>;
};

export enum PaymentMethodTypesEnum {
  Auto = 'AUTO',
  Direct = 'DIRECT',
  Swap = 'SWAP',
}

/** Boolean expression to compare columns of type "PaymentMethodTypesEnum". All fields are combined with logical 'AND'. */
export type PaymentMethodTypesEnumComparisonExp = {
  _eq?: InputMaybe<PaymentMethodTypesEnum>;
  _in?: InputMaybe<Array<PaymentMethodTypesEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<PaymentMethodTypesEnum>;
  _nin?: InputMaybe<Array<PaymentMethodTypesEnum>>;
};

/** Default Payment method for account */
export type PaymentMethods = {
  __typename?: 'PaymentMethods';
  /** An object relationship */
  account: Accounts;
  accountId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  /** An array relationship */
  targetChains: Array<PaymentMethodChains>;
  type: PaymentMethodTypesEnum;
  updatedAt: Scalars['timestamptz'];
};

/** Default Payment method for account */
export type PaymentMethodsTargetChainsArgs = {
  distinctOn?: InputMaybe<Array<PaymentMethodChainsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentMethodChainsOrderBy>>;
  where?: InputMaybe<PaymentMethodChainsBoolExp>;
};

/** aggregated selection of "PaymentMethods" */
export type PaymentMethodsAggregate = {
  __typename?: 'PaymentMethodsAggregate';
  aggregate?: Maybe<PaymentMethodsAggregateFields>;
  nodes: Array<PaymentMethods>;
};

export type PaymentMethodsAggregateBoolExp = {
  bool_and?: InputMaybe<PaymentMethodsAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<PaymentMethodsAggregateBoolExpBool_Or>;
  count?: InputMaybe<PaymentMethodsAggregateBoolExpCount>;
};

/** aggregate fields of "PaymentMethods" */
export type PaymentMethodsAggregateFields = {
  __typename?: 'PaymentMethodsAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<PaymentMethodsMaxFields>;
  min?: Maybe<PaymentMethodsMinFields>;
};

/** aggregate fields of "PaymentMethods" */
export type PaymentMethodsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PaymentMethodsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "PaymentMethods" */
export type PaymentMethodsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PaymentMethodsMaxOrderBy>;
  min?: InputMaybe<PaymentMethodsMinOrderBy>;
};

/** input type for inserting array relation for remote table "PaymentMethods" */
export type PaymentMethodsArrRelInsertInput = {
  data: Array<PaymentMethodsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<PaymentMethodsOnConflict>;
};

/** Boolean expression to filter rows from the table "PaymentMethods". All fields are combined with a logical 'AND'. */
export type PaymentMethodsBoolExp = {
  _and?: InputMaybe<Array<PaymentMethodsBoolExp>>;
  _not?: InputMaybe<PaymentMethodsBoolExp>;
  _or?: InputMaybe<Array<PaymentMethodsBoolExp>>;
  account?: InputMaybe<AccountsBoolExp>;
  accountId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  isActive?: InputMaybe<BooleanComparisonExp>;
  isDefault?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  targetChains?: InputMaybe<PaymentMethodChainsBoolExp>;
  type?: InputMaybe<PaymentMethodTypesEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "PaymentMethods" */
export enum PaymentMethodsConstraint {
  /** unique or primary key constraint on columns "id" */
  AccountPaymentMethodsPkey = 'AccountPaymentMethods_pkey',
}

/** input type for inserting data into table "PaymentMethods" */
export type PaymentMethodsInsertInput = {
  account?: InputMaybe<AccountsObjRelInsertInput>;
  accountId?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  targetChains?: InputMaybe<PaymentMethodChainsArrRelInsertInput>;
};

/** aggregate max on columns */
export type PaymentMethodsMaxFields = {
  __typename?: 'PaymentMethodsMaxFields';
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "PaymentMethods" */
export type PaymentMethodsMaxOrderBy = {
  accountId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type PaymentMethodsMinFields = {
  __typename?: 'PaymentMethodsMinFields';
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "PaymentMethods" */
export type PaymentMethodsMinOrderBy = {
  accountId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "PaymentMethods" */
export type PaymentMethodsMutationResponse = {
  __typename?: 'PaymentMethodsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PaymentMethods>;
};

/** input type for inserting object relation for remote table "PaymentMethods" */
export type PaymentMethodsObjRelInsertInput = {
  data: PaymentMethodsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<PaymentMethodsOnConflict>;
};

/** on_conflict condition type for table "PaymentMethods" */
export type PaymentMethodsOnConflict = {
  constraint: PaymentMethodsConstraint;
  updateColumns?: Array<PaymentMethodsUpdateColumn>;
  where?: InputMaybe<PaymentMethodsBoolExp>;
};

/** Ordering options when selecting data from "PaymentMethods". */
export type PaymentMethodsOrderBy = {
  account?: InputMaybe<AccountsOrderBy>;
  accountId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isActive?: InputMaybe<OrderBy>;
  isDefault?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  targetChainsAggregate?: InputMaybe<PaymentMethodChainsAggregateOrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: PaymentMethods */
export type PaymentMethodsPkColumnsInput = {
  id: Scalars['String'];
};

/** select columns of table "PaymentMethods" */
export enum PaymentMethodsSelectColumn {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsDefault = 'isDefault',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** select "paymentMethodsAggregateBoolExpBool_andArgumentsColumns" columns of table "PaymentMethods" */
export enum PaymentMethodsSelectColumnPaymentMethodsAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsDefault = 'isDefault',
}

/** select "paymentMethodsAggregateBoolExpBool_orArgumentsColumns" columns of table "PaymentMethods" */
export enum PaymentMethodsSelectColumnPaymentMethodsAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsDefault = 'isDefault',
}

/** input type for updating data in table "PaymentMethods" */
export type PaymentMethodsSetInput = {
  isActive?: InputMaybe<Scalars['Boolean']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "PaymentMethods" */
export type PaymentMethodsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PaymentMethodsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PaymentMethodsStreamCursorValueInput = {
  accountId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PaymentMethodTypesEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "PaymentMethods" */
export enum PaymentMethodsUpdateColumn {
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsDefault = 'isDefault',
  /** column name */
  Name = 'name',
}

export type PaymentMethodsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PaymentMethodsSetInput>;
  /** filter the rows which have to be updated */
  where: PaymentMethodsBoolExp;
};

export type RequestUploadOutput = {
  __typename?: 'RequestUploadOutput';
  newImageURI: Scalars['String'];
  putUrl: Scalars['String'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "TokenContracts" */
export type TokenContracts = {
  __typename?: 'TokenContracts';
  address: Scalars['String'];
  /** An object relationship */
  chain: Chains;
  chainId: Scalars['Int'];
  /** An object relationship */
  coin: Coins;
  coinId: Scalars['String'];
  createdAt?: Maybe<Scalars['timestamptz']>;
  decimals: Scalars['Int'];
  id: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "TokenContracts" */
export type TokenContractsAggregate = {
  __typename?: 'TokenContractsAggregate';
  aggregate?: Maybe<TokenContractsAggregateFields>;
  nodes: Array<TokenContracts>;
};

export type TokenContractsAggregateBoolExp = {
  count?: InputMaybe<TokenContractsAggregateBoolExpCount>;
};

/** aggregate fields of "TokenContracts" */
export type TokenContractsAggregateFields = {
  __typename?: 'TokenContractsAggregateFields';
  avg?: Maybe<TokenContractsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TokenContractsMaxFields>;
  min?: Maybe<TokenContractsMinFields>;
  stddev?: Maybe<TokenContractsStddevFields>;
  stddevPop?: Maybe<TokenContractsStddevPopFields>;
  stddevSamp?: Maybe<TokenContractsStddevSampFields>;
  sum?: Maybe<TokenContractsSumFields>;
  varPop?: Maybe<TokenContractsVarPopFields>;
  varSamp?: Maybe<TokenContractsVarSampFields>;
  variance?: Maybe<TokenContractsVarianceFields>;
};

/** aggregate fields of "TokenContracts" */
export type TokenContractsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TokenContractsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "TokenContracts" */
export type TokenContractsAggregateOrderBy = {
  avg?: InputMaybe<TokenContractsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TokenContractsMaxOrderBy>;
  min?: InputMaybe<TokenContractsMinOrderBy>;
  stddev?: InputMaybe<TokenContractsStddevOrderBy>;
  stddevPop?: InputMaybe<TokenContractsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<TokenContractsStddevSampOrderBy>;
  sum?: InputMaybe<TokenContractsSumOrderBy>;
  varPop?: InputMaybe<TokenContractsVarPopOrderBy>;
  varSamp?: InputMaybe<TokenContractsVarSampOrderBy>;
  variance?: InputMaybe<TokenContractsVarianceOrderBy>;
};

/** aggregate avg on columns */
export type TokenContractsAvgFields = {
  __typename?: 'TokenContractsAvgFields';
  chainId?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "TokenContracts" */
export type TokenContractsAvgOrderBy = {
  chainId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "TokenContracts". All fields are combined with a logical 'AND'. */
export type TokenContractsBoolExp = {
  _and?: InputMaybe<Array<TokenContractsBoolExp>>;
  _not?: InputMaybe<TokenContractsBoolExp>;
  _or?: InputMaybe<Array<TokenContractsBoolExp>>;
  address?: InputMaybe<StringComparisonExp>;
  chain?: InputMaybe<ChainsBoolExp>;
  chainId?: InputMaybe<IntComparisonExp>;
  coin?: InputMaybe<CoinsBoolExp>;
  coinId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  decimals?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type TokenContractsMaxFields = {
  __typename?: 'TokenContractsMaxFields';
  address?: Maybe<Scalars['String']>;
  chainId?: Maybe<Scalars['Int']>;
  coinId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  decimals?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "TokenContracts" */
export type TokenContractsMaxOrderBy = {
  address?: InputMaybe<OrderBy>;
  chainId?: InputMaybe<OrderBy>;
  coinId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TokenContractsMinFields = {
  __typename?: 'TokenContractsMinFields';
  address?: Maybe<Scalars['String']>;
  chainId?: Maybe<Scalars['Int']>;
  coinId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  decimals?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "TokenContracts" */
export type TokenContractsMinOrderBy = {
  address?: InputMaybe<OrderBy>;
  chainId?: InputMaybe<OrderBy>;
  coinId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "TokenContracts". */
export type TokenContractsOrderBy = {
  address?: InputMaybe<OrderBy>;
  chain?: InputMaybe<ChainsOrderBy>;
  chainId?: InputMaybe<OrderBy>;
  coin?: InputMaybe<CoinsOrderBy>;
  coinId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** select columns of table "TokenContracts" */
export enum TokenContractsSelectColumn {
  /** column name */
  Address = 'address',
  /** column name */
  ChainId = 'chainId',
  /** column name */
  CoinId = 'coinId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Decimals = 'decimals',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
}

/** aggregate stddev on columns */
export type TokenContractsStddevFields = {
  __typename?: 'TokenContractsStddevFields';
  chainId?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "TokenContracts" */
export type TokenContractsStddevOrderBy = {
  chainId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type TokenContractsStddevPopFields = {
  __typename?: 'TokenContractsStddevPopFields';
  chainId?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "TokenContracts" */
export type TokenContractsStddevPopOrderBy = {
  chainId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type TokenContractsStddevSampFields = {
  __typename?: 'TokenContractsStddevSampFields';
  chainId?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "TokenContracts" */
export type TokenContractsStddevSampOrderBy = {
  chainId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type TokenContractsSumFields = {
  __typename?: 'TokenContractsSumFields';
  chainId?: Maybe<Scalars['Int']>;
  decimals?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "TokenContracts" */
export type TokenContractsSumOrderBy = {
  chainId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type TokenContractsVarPopFields = {
  __typename?: 'TokenContractsVarPopFields';
  chainId?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "TokenContracts" */
export type TokenContractsVarPopOrderBy = {
  chainId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type TokenContractsVarSampFields = {
  __typename?: 'TokenContractsVarSampFields';
  chainId?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "TokenContracts" */
export type TokenContractsVarSampOrderBy = {
  chainId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type TokenContractsVarianceFields = {
  __typename?: 'TokenContractsVarianceFields';
  chainId?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "TokenContracts" */
export type TokenContractsVarianceOrderBy = {
  chainId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
};

export enum UploadType {
  AccountAvatar = 'ACCOUNT_AVATAR',
  AppIcon = 'APP_ICON',
  AppLogo = 'APP_LOGO',
  UserAvatar = 'USER_AVATAR',
}

/** columns and relationships of "Users" */
export type Users = {
  __typename?: 'Users';
  /** An array relationship */
  accountMembers: Array<AccountMembers>;
  /** An aggregate relationship */
  accountMembersAggregate: AccountMembersAggregate;
  /** An array relationship */
  accounts: Array<Accounts>;
  /** An aggregate relationship */
  accountsAggregate: AccountsAggregate;
  address?: Maybe<Scalars['String']>;
  avatarURI?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  /** An object relationship */
  country?: Maybe<Countries>;
  countryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  defaultAccountId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastSeen?: Maybe<Scalars['timestamptz']>;
  phoneNumber?: Maybe<Scalars['String']>;
  publicAddress?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "Users" */
export type UsersAccountMembersArgs = {
  distinctOn?: InputMaybe<Array<AccountMembersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountMembersOrderBy>>;
  where?: InputMaybe<AccountMembersBoolExp>;
};

/** columns and relationships of "Users" */
export type UsersAccountMembersAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccountMembersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountMembersOrderBy>>;
  where?: InputMaybe<AccountMembersBoolExp>;
};

/** columns and relationships of "Users" */
export type UsersAccountsArgs = {
  distinctOn?: InputMaybe<Array<AccountsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountsOrderBy>>;
  where?: InputMaybe<AccountsBoolExp>;
};

/** columns and relationships of "Users" */
export type UsersAccountsAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccountsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountsOrderBy>>;
  where?: InputMaybe<AccountsBoolExp>;
};

/** Boolean expression to filter rows from the table "Users". All fields are combined with a logical 'AND'. */
export type UsersBoolExp = {
  _and?: InputMaybe<Array<UsersBoolExp>>;
  _not?: InputMaybe<UsersBoolExp>;
  _or?: InputMaybe<Array<UsersBoolExp>>;
  accountMembers?: InputMaybe<AccountMembersBoolExp>;
  accountMembersAggregate?: InputMaybe<AccountMembersAggregateBoolExp>;
  accounts?: InputMaybe<AccountsBoolExp>;
  accountsAggregate?: InputMaybe<AccountsAggregateBoolExp>;
  address?: InputMaybe<StringComparisonExp>;
  avatarURI?: InputMaybe<StringComparisonExp>;
  city?: InputMaybe<StringComparisonExp>;
  country?: InputMaybe<CountriesBoolExp>;
  countryId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  defaultAccountId?: InputMaybe<StringComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  fullName?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lastSeen?: InputMaybe<TimestamptzComparisonExp>;
  phoneNumber?: InputMaybe<StringComparisonExp>;
  publicAddress?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** response of any mutation on the table "Users" */
export type UsersMutationResponse = {
  __typename?: 'UsersMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** Ordering options when selecting data from "Users". */
export type UsersOrderBy = {
  accountMembersAggregate?: InputMaybe<AccountMembersAggregateOrderBy>;
  accountsAggregate?: InputMaybe<AccountsAggregateOrderBy>;
  address?: InputMaybe<OrderBy>;
  avatarURI?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  country?: InputMaybe<CountriesOrderBy>;
  countryId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  defaultAccountId?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  fullName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastSeen?: InputMaybe<OrderBy>;
  phoneNumber?: InputMaybe<OrderBy>;
  publicAddress?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: Users */
export type UsersPkColumnsInput = {
  id: Scalars['String'];
};

/** select columns of table "Users" */
export enum UsersSelectColumn {
  /** column name */
  Address = 'address',
  /** column name */
  AvatarUri = 'avatarURI',
  /** column name */
  City = 'city',
  /** column name */
  CountryId = 'countryId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DefaultAccountId = 'defaultAccountId',
  /** column name */
  Email = 'email',
  /** column name */
  FullName = 'fullName',
  /** column name */
  Id = 'id',
  /** column name */
  LastSeen = 'lastSeen',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PublicAddress = 'publicAddress',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "Users" */
export type UsersSetInput = {
  address?: InputMaybe<Scalars['String']>;
  avatarURI?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['String']>;
  defaultAccountId?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "Users" */
export type UsersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: UsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UsersStreamCursorValueInput = {
  address?: InputMaybe<Scalars['String']>;
  avatarURI?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  defaultAccountId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastSeen?: InputMaybe<Scalars['timestamptz']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  publicAddress?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

export type UsersUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UsersSetInput>;
  /** filter the rows which have to be updated */
  where: UsersBoolExp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type UuidComparisonExp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** Boolean expression to compare columns of type "_text". All fields are combined with logical 'AND'. */
export type _TextComparisonExp = {
  _eq?: InputMaybe<Scalars['_text']>;
  _gt?: InputMaybe<Scalars['_text']>;
  _gte?: InputMaybe<Scalars['_text']>;
  _in?: InputMaybe<Array<Scalars['_text']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['_text']>;
  _lte?: InputMaybe<Scalars['_text']>;
  _neq?: InputMaybe<Scalars['_text']>;
  _nin?: InputMaybe<Array<Scalars['_text']>>;
};

export type AccountMembersAggregateBoolExpBool_And = {
  arguments: AccountMembersSelectColumnAccountMembersAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AccountMembersBoolExp>;
  predicate: BooleanComparisonExp;
};

export type AccountMembersAggregateBoolExpBool_Or = {
  arguments: AccountMembersSelectColumnAccountMembersAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AccountMembersBoolExp>;
  predicate: BooleanComparisonExp;
};

export type AccountMembersAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AccountMembersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AccountMembersBoolExp>;
  predicate: IntComparisonExp;
};

export type AccountsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AccountsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AccountsBoolExp>;
  predicate: IntComparisonExp;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  connectWallet?: Maybe<ConnectWalletOutput>;
  /** delete data from the table: "AccountMembers" */
  deleteAccountMembers?: Maybe<AccountMembersMutationResponse>;
  /** delete single row from the table: "AccountMembers" */
  deleteAccountMembersByPk?: Maybe<AccountMembers>;
  /** delete data from the table: "Customers" */
  deleteCustomers?: Maybe<CustomersMutationResponse>;
  /** delete single row from the table: "Customers" */
  deleteCustomersByPk?: Maybe<Customers>;
  /** delete data from the table: "PaymentMethods" */
  deletePaymentMethods?: Maybe<PaymentMethodsMutationResponse>;
  /** delete single row from the table: "PaymentMethods" */
  deletePaymentMethodsByPk?: Maybe<PaymentMethods>;
  initial: InitialOutput;
  /** insert data into the table: "Accounts" */
  insertAccounts?: Maybe<AccountsMutationResponse>;
  /** insert a single row into the table: "Accounts" */
  insertAccountsOne?: Maybe<Accounts>;
  /** insert data into the table: "Customers" */
  insertCustomers?: Maybe<CustomersMutationResponse>;
  /** insert a single row into the table: "Customers" */
  insertCustomersOne?: Maybe<Customers>;
  /** insert data into the table: "PaymentMethodChains" */
  insertPaymentMethodChains?: Maybe<PaymentMethodChainsMutationResponse>;
  /** insert a single row into the table: "PaymentMethodChains" */
  insertPaymentMethodChainsOne?: Maybe<PaymentMethodChains>;
  /** insert data into the table: "PaymentMethods" */
  insertPaymentMethods?: Maybe<PaymentMethodsMutationResponse>;
  /** insert a single row into the table: "PaymentMethods" */
  insertPaymentMethodsOne?: Maybe<PaymentMethods>;
  requestUpload?: Maybe<RequestUploadOutput>;
  /** update data of the table: "AccountMembers" */
  updateAccountMembers?: Maybe<AccountMembersMutationResponse>;
  /** update single row of the table: "AccountMembers" */
  updateAccountMembersByPk?: Maybe<AccountMembers>;
  /** update multiples rows of table: "AccountMembers" */
  updateAccountMembersMany?: Maybe<Array<Maybe<AccountMembersMutationResponse>>>;
  /** update data of the table: "Accounts" */
  updateAccounts?: Maybe<AccountsMutationResponse>;
  /** update single row of the table: "Accounts" */
  updateAccountsByPk?: Maybe<Accounts>;
  /** update multiples rows of table: "Accounts" */
  updateAccountsMany?: Maybe<Array<Maybe<AccountsMutationResponse>>>;
  /** update data of the table: "Customers" */
  updateCustomers?: Maybe<CustomersMutationResponse>;
  /** update single row of the table: "Customers" */
  updateCustomersByPk?: Maybe<Customers>;
  /** update multiples rows of table: "Customers" */
  updateCustomersMany?: Maybe<Array<Maybe<CustomersMutationResponse>>>;
  /** update data of the table: "PaymentMethods" */
  updatePaymentMethods?: Maybe<PaymentMethodsMutationResponse>;
  /** update single row of the table: "PaymentMethods" */
  updatePaymentMethodsByPk?: Maybe<PaymentMethods>;
  /** update multiples rows of table: "PaymentMethods" */
  updatePaymentMethodsMany?: Maybe<Array<Maybe<PaymentMethodsMutationResponse>>>;
  /** update data of the table: "Users" */
  updateUsers?: Maybe<UsersMutationResponse>;
  /** update single row of the table: "Users" */
  updateUsersByPk?: Maybe<Users>;
  /** update multiples rows of table: "Users" */
  updateUsersMany?: Maybe<Array<Maybe<UsersMutationResponse>>>;
};

/** mutation root */
export type Mutation_RootConnectWalletArgs = {
  message: Scalars['String'];
  signature: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteAccountMembersArgs = {
  where: AccountMembersBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteAccountMembersByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteCustomersArgs = {
  where: CustomersBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCustomersByPkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeletePaymentMethodsArgs = {
  where: PaymentMethodsBoolExp;
};

/** mutation root */
export type Mutation_RootDeletePaymentMethodsByPkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootInitialArgs = {
  avatarURI?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootInsertAccountsArgs = {
  objects: Array<AccountsInsertInput>;
  onConflict?: InputMaybe<AccountsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertAccountsOneArgs = {
  object: AccountsInsertInput;
  onConflict?: InputMaybe<AccountsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCustomersArgs = {
  objects: Array<CustomersInsertInput>;
  onConflict?: InputMaybe<CustomersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCustomersOneArgs = {
  object: CustomersInsertInput;
  onConflict?: InputMaybe<CustomersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPaymentMethodChainsArgs = {
  objects: Array<PaymentMethodChainsInsertInput>;
  onConflict?: InputMaybe<PaymentMethodChainsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPaymentMethodChainsOneArgs = {
  object: PaymentMethodChainsInsertInput;
  onConflict?: InputMaybe<PaymentMethodChainsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPaymentMethodsArgs = {
  objects: Array<PaymentMethodsInsertInput>;
  onConflict?: InputMaybe<PaymentMethodsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPaymentMethodsOneArgs = {
  object: PaymentMethodsInsertInput;
  onConflict?: InputMaybe<PaymentMethodsOnConflict>;
};

/** mutation root */
export type Mutation_RootRequestUploadArgs = {
  id: Scalars['String'];
  upType: UploadType;
};

/** mutation root */
export type Mutation_RootUpdateAccountMembersArgs = {
  _set?: InputMaybe<AccountMembersSetInput>;
  where: AccountMembersBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateAccountMembersByPkArgs = {
  _set?: InputMaybe<AccountMembersSetInput>;
  pkColumns: AccountMembersPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateAccountMembersManyArgs = {
  updates: Array<AccountMembersUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateAccountsArgs = {
  _set?: InputMaybe<AccountsSetInput>;
  where: AccountsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateAccountsByPkArgs = {
  _set?: InputMaybe<AccountsSetInput>;
  pkColumns: AccountsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateAccountsManyArgs = {
  updates: Array<AccountsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCustomersArgs = {
  _set?: InputMaybe<CustomersSetInput>;
  where: CustomersBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCustomersByPkArgs = {
  _set?: InputMaybe<CustomersSetInput>;
  pkColumns: CustomersPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCustomersManyArgs = {
  updates: Array<CustomersUpdates>;
};

/** mutation root */
export type Mutation_RootUpdatePaymentMethodsArgs = {
  _set?: InputMaybe<PaymentMethodsSetInput>;
  where: PaymentMethodsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdatePaymentMethodsByPkArgs = {
  _set?: InputMaybe<PaymentMethodsSetInput>;
  pkColumns: PaymentMethodsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdatePaymentMethodsManyArgs = {
  updates: Array<PaymentMethodsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateUsersArgs = {
  _set?: InputMaybe<UsersSetInput>;
  where: UsersBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateUsersByPkArgs = {
  _set?: InputMaybe<UsersSetInput>;
  pkColumns: UsersPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateUsersManyArgs = {
  updates: Array<UsersUpdates>;
};

export type PaymentMethodsAggregateBoolExpBool_And = {
  arguments: PaymentMethodsSelectColumnPaymentMethodsAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PaymentMethodsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type PaymentMethodsAggregateBoolExpBool_Or = {
  arguments: PaymentMethodsSelectColumnPaymentMethodsAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PaymentMethodsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type PaymentMethodsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<PaymentMethodsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PaymentMethodsBoolExp>;
  predicate: IntComparisonExp;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "AccountMemberTypes" */
  accountMemberTypes: Array<AccountMemberTypes>;
  /** fetch data from the table: "AccountMemberTypes" using primary key columns */
  accountMemberTypesByPk?: Maybe<AccountMemberTypes>;
  /** An array relationship */
  accountMembers: Array<AccountMembers>;
  /** An aggregate relationship */
  accountMembersAggregate: AccountMembersAggregate;
  /** fetch data from the table: "AccountMembers" using primary key columns */
  accountMembersByPk?: Maybe<AccountMembers>;
  /** An array relationship */
  accounts: Array<Accounts>;
  /** An aggregate relationship */
  accountsAggregate: AccountsAggregate;
  /** fetch data from the table: "Accounts" using primary key columns */
  accountsByPk?: Maybe<Accounts>;
  /** fetch data from the table: "AllowedCoins" */
  allowedCoins: Array<AllowedCoins>;
  /** fetch data from the table: "AllowedCoins" using primary key columns */
  allowedCoinsByPk?: Maybe<AllowedCoins>;
  /** An array relationship */
  chains: Array<Chains>;
  /** fetch data from the table: "Chains" using primary key columns */
  chainsByPk?: Maybe<Chains>;
  /** fetch data from the table: "Coins" */
  coins: Array<Coins>;
  /** fetch aggregated fields from the table: "Coins" */
  coinsAggregate: CoinsAggregate;
  /** fetch data from the table: "Coins" using primary key columns */
  coinsByPk?: Maybe<Coins>;
  /** fetch data from the table: "Countries" */
  countries: Array<Countries>;
  /** fetch data from the table: "Countries" using primary key columns */
  countriesByPk?: Maybe<Countries>;
  /** fetch data from the table: "Currencies" */
  currencies: Array<Currencies>;
  /** fetch data from the table: "Currencies" using primary key columns */
  currenciesByPk?: Maybe<Currencies>;
  /** An array relationship */
  customers: Array<Customers>;
  /** fetch data from the table: "Customers" using primary key columns */
  customersByPk?: Maybe<Customers>;
  /** fetch data from the table: "PaymentMethodChains" */
  paymentMethodChains: Array<PaymentMethodChains>;
  /** fetch data from the table: "PaymentMethodChains" using primary key columns */
  paymentMethodChainsByPk?: Maybe<PaymentMethodChains>;
  /** fetch data from the table: "PaymentMethods" */
  paymentMethods: Array<PaymentMethods>;
  /** fetch aggregated fields from the table: "PaymentMethods" */
  paymentMethodsAggregate: PaymentMethodsAggregate;
  /** fetch data from the table: "PaymentMethods" using primary key columns */
  paymentMethodsByPk?: Maybe<PaymentMethods>;
  /** An array relationship */
  tokenContracts: Array<TokenContracts>;
  /** An aggregate relationship */
  tokenContractsAggregate: TokenContractsAggregate;
  /** fetch data from the table: "TokenContracts" using primary key columns */
  tokenContractsByPk?: Maybe<TokenContracts>;
  /** fetch data from the table: "Users" */
  users: Array<Users>;
  /** fetch data from the table: "Users" using primary key columns */
  usersByPk?: Maybe<Users>;
};

export type Query_RootAccountMemberTypesArgs = {
  distinctOn?: InputMaybe<Array<AccountMemberTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountMemberTypesOrderBy>>;
  where?: InputMaybe<AccountMemberTypesBoolExp>;
};

export type Query_RootAccountMemberTypesByPkArgs = {
  id: Scalars['String'];
};

export type Query_RootAccountMembersArgs = {
  distinctOn?: InputMaybe<Array<AccountMembersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountMembersOrderBy>>;
  where?: InputMaybe<AccountMembersBoolExp>;
};

export type Query_RootAccountMembersAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccountMembersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountMembersOrderBy>>;
  where?: InputMaybe<AccountMembersBoolExp>;
};

export type Query_RootAccountMembersByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAccountsArgs = {
  distinctOn?: InputMaybe<Array<AccountsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountsOrderBy>>;
  where?: InputMaybe<AccountsBoolExp>;
};

export type Query_RootAccountsAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccountsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountsOrderBy>>;
  where?: InputMaybe<AccountsBoolExp>;
};

export type Query_RootAccountsByPkArgs = {
  id: Scalars['String'];
};

export type Query_RootAllowedCoinsArgs = {
  distinctOn?: InputMaybe<Array<AllowedCoinsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AllowedCoinsOrderBy>>;
  where?: InputMaybe<AllowedCoinsBoolExp>;
};

export type Query_RootAllowedCoinsByPkArgs = {
  coinId: Scalars['String'];
};

export type Query_RootChainsArgs = {
  distinctOn?: InputMaybe<Array<ChainsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ChainsOrderBy>>;
  where?: InputMaybe<ChainsBoolExp>;
};

export type Query_RootChainsByPkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCoinsArgs = {
  distinctOn?: InputMaybe<Array<CoinsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoinsOrderBy>>;
  where?: InputMaybe<CoinsBoolExp>;
};

export type Query_RootCoinsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CoinsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoinsOrderBy>>;
  where?: InputMaybe<CoinsBoolExp>;
};

export type Query_RootCoinsByPkArgs = {
  id: Scalars['String'];
};

export type Query_RootCountriesArgs = {
  distinctOn?: InputMaybe<Array<CountriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CountriesOrderBy>>;
  where?: InputMaybe<CountriesBoolExp>;
};

export type Query_RootCountriesByPkArgs = {
  id: Scalars['String'];
};

export type Query_RootCurrenciesArgs = {
  distinctOn?: InputMaybe<Array<CurrenciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrenciesOrderBy>>;
  where?: InputMaybe<CurrenciesBoolExp>;
};

export type Query_RootCurrenciesByPkArgs = {
  id: Scalars['String'];
};

export type Query_RootCustomersArgs = {
  distinctOn?: InputMaybe<Array<CustomersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CustomersOrderBy>>;
  where?: InputMaybe<CustomersBoolExp>;
};

export type Query_RootCustomersByPkArgs = {
  id: Scalars['String'];
};

export type Query_RootPaymentMethodChainsArgs = {
  distinctOn?: InputMaybe<Array<PaymentMethodChainsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentMethodChainsOrderBy>>;
  where?: InputMaybe<PaymentMethodChainsBoolExp>;
};

export type Query_RootPaymentMethodChainsByPkArgs = {
  id: Scalars['String'];
};

export type Query_RootPaymentMethodsArgs = {
  distinctOn?: InputMaybe<Array<PaymentMethodsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentMethodsOrderBy>>;
  where?: InputMaybe<PaymentMethodsBoolExp>;
};

export type Query_RootPaymentMethodsAggregateArgs = {
  distinctOn?: InputMaybe<Array<PaymentMethodsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentMethodsOrderBy>>;
  where?: InputMaybe<PaymentMethodsBoolExp>;
};

export type Query_RootPaymentMethodsByPkArgs = {
  id: Scalars['String'];
};

export type Query_RootTokenContractsArgs = {
  distinctOn?: InputMaybe<Array<TokenContractsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TokenContractsOrderBy>>;
  where?: InputMaybe<TokenContractsBoolExp>;
};

export type Query_RootTokenContractsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TokenContractsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TokenContractsOrderBy>>;
  where?: InputMaybe<TokenContractsBoolExp>;
};

export type Query_RootTokenContractsByPkArgs = {
  id: Scalars['String'];
};

export type Query_RootUsersArgs = {
  distinctOn?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};

export type Query_RootUsersByPkArgs = {
  id: Scalars['String'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "AccountMemberTypes" */
  accountMemberTypes: Array<AccountMemberTypes>;
  /** fetch data from the table: "AccountMemberTypes" using primary key columns */
  accountMemberTypesByPk?: Maybe<AccountMemberTypes>;
  /** fetch data from the table in a streaming manner: "AccountMemberTypes" */
  accountMemberTypesStream: Array<AccountMemberTypes>;
  /** An array relationship */
  accounts: Array<Accounts>;
  /** An aggregate relationship */
  accountsAggregate: AccountsAggregate;
  /** fetch data from the table: "Accounts" using primary key columns */
  accountsByPk?: Maybe<Accounts>;
  /** fetch data from the table in a streaming manner: "Accounts" */
  accountsStream: Array<Accounts>;
  /** fetch data from the table: "AllowedCoins" */
  allowedCoins: Array<AllowedCoins>;
  /** fetch data from the table: "AllowedCoins" using primary key columns */
  allowedCoinsByPk?: Maybe<AllowedCoins>;
  /** fetch data from the table in a streaming manner: "AllowedCoins" */
  allowedCoinsStream: Array<AllowedCoins>;
  /** fetch data from the table: "Currencies" */
  currencies: Array<Currencies>;
  /** fetch data from the table: "Currencies" using primary key columns */
  currenciesByPk?: Maybe<Currencies>;
  /** fetch data from the table in a streaming manner: "Currencies" */
  currenciesStream: Array<Currencies>;
  /** An array relationship */
  customers: Array<Customers>;
  /** fetch data from the table: "Customers" using primary key columns */
  customersByPk?: Maybe<Customers>;
  /** fetch data from the table in a streaming manner: "Customers" */
  customersStream: Array<Customers>;
  /** fetch data from the table: "PaymentMethodChains" */
  paymentMethodChains: Array<PaymentMethodChains>;
  /** fetch data from the table: "PaymentMethodChains" using primary key columns */
  paymentMethodChainsByPk?: Maybe<PaymentMethodChains>;
  /** fetch data from the table in a streaming manner: "PaymentMethodChains" */
  paymentMethodChainsStream: Array<PaymentMethodChains>;
  /** fetch data from the table: "PaymentMethods" */
  paymentMethods: Array<PaymentMethods>;
  /** fetch aggregated fields from the table: "PaymentMethods" */
  paymentMethodsAggregate: PaymentMethodsAggregate;
  /** fetch data from the table: "PaymentMethods" using primary key columns */
  paymentMethodsByPk?: Maybe<PaymentMethods>;
  /** fetch data from the table in a streaming manner: "PaymentMethods" */
  paymentMethodsStream: Array<PaymentMethods>;
  /** fetch data from the table: "Users" */
  users: Array<Users>;
  /** fetch data from the table: "Users" using primary key columns */
  usersByPk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "Users" */
  usersStream: Array<Users>;
};

export type Subscription_RootAccountMemberTypesArgs = {
  distinctOn?: InputMaybe<Array<AccountMemberTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountMemberTypesOrderBy>>;
  where?: InputMaybe<AccountMemberTypesBoolExp>;
};

export type Subscription_RootAccountMemberTypesByPkArgs = {
  id: Scalars['String'];
};

export type Subscription_RootAccountMemberTypesStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<AccountMemberTypesStreamCursorInput>>;
  where?: InputMaybe<AccountMemberTypesBoolExp>;
};

export type Subscription_RootAccountsArgs = {
  distinctOn?: InputMaybe<Array<AccountsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountsOrderBy>>;
  where?: InputMaybe<AccountsBoolExp>;
};

export type Subscription_RootAccountsAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccountsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountsOrderBy>>;
  where?: InputMaybe<AccountsBoolExp>;
};

export type Subscription_RootAccountsByPkArgs = {
  id: Scalars['String'];
};

export type Subscription_RootAccountsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<AccountsStreamCursorInput>>;
  where?: InputMaybe<AccountsBoolExp>;
};

export type Subscription_RootAllowedCoinsArgs = {
  distinctOn?: InputMaybe<Array<AllowedCoinsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AllowedCoinsOrderBy>>;
  where?: InputMaybe<AllowedCoinsBoolExp>;
};

export type Subscription_RootAllowedCoinsByPkArgs = {
  coinId: Scalars['String'];
};

export type Subscription_RootAllowedCoinsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<AllowedCoinsStreamCursorInput>>;
  where?: InputMaybe<AllowedCoinsBoolExp>;
};

export type Subscription_RootCurrenciesArgs = {
  distinctOn?: InputMaybe<Array<CurrenciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrenciesOrderBy>>;
  where?: InputMaybe<CurrenciesBoolExp>;
};

export type Subscription_RootCurrenciesByPkArgs = {
  id: Scalars['String'];
};

export type Subscription_RootCurrenciesStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CurrenciesStreamCursorInput>>;
  where?: InputMaybe<CurrenciesBoolExp>;
};

export type Subscription_RootCustomersArgs = {
  distinctOn?: InputMaybe<Array<CustomersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CustomersOrderBy>>;
  where?: InputMaybe<CustomersBoolExp>;
};

export type Subscription_RootCustomersByPkArgs = {
  id: Scalars['String'];
};

export type Subscription_RootCustomersStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CustomersStreamCursorInput>>;
  where?: InputMaybe<CustomersBoolExp>;
};

export type Subscription_RootPaymentMethodChainsArgs = {
  distinctOn?: InputMaybe<Array<PaymentMethodChainsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentMethodChainsOrderBy>>;
  where?: InputMaybe<PaymentMethodChainsBoolExp>;
};

export type Subscription_RootPaymentMethodChainsByPkArgs = {
  id: Scalars['String'];
};

export type Subscription_RootPaymentMethodChainsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<PaymentMethodChainsStreamCursorInput>>;
  where?: InputMaybe<PaymentMethodChainsBoolExp>;
};

export type Subscription_RootPaymentMethodsArgs = {
  distinctOn?: InputMaybe<Array<PaymentMethodsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentMethodsOrderBy>>;
  where?: InputMaybe<PaymentMethodsBoolExp>;
};

export type Subscription_RootPaymentMethodsAggregateArgs = {
  distinctOn?: InputMaybe<Array<PaymentMethodsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentMethodsOrderBy>>;
  where?: InputMaybe<PaymentMethodsBoolExp>;
};

export type Subscription_RootPaymentMethodsByPkArgs = {
  id: Scalars['String'];
};

export type Subscription_RootPaymentMethodsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<PaymentMethodsStreamCursorInput>>;
  where?: InputMaybe<PaymentMethodsBoolExp>;
};

export type Subscription_RootUsersArgs = {
  distinctOn?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};

export type Subscription_RootUsersByPkArgs = {
  id: Scalars['String'];
};

export type Subscription_RootUsersStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<UsersStreamCursorInput>>;
  where?: InputMaybe<UsersBoolExp>;
};

export type TokenContractsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TokenContractsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TokenContractsBoolExp>;
  predicate: IntComparisonExp;
};

export type InitialMutationVariables = Exact<{
  avatarURI?: InputMaybe<Scalars['String']>;
}>;

export type InitialMutation = {
  __typename?: 'mutation_root';
  initial: {
    __typename?: 'InitialOutput';
    user?: {
      __typename?: 'Users';
      id: string;
      defaultAccountId?: string | null;
      ownerAccounts: Array<{
        __typename?: 'Accounts';
        id: string;
        name: string;
        logoURI?: string | null;
        ownerId: string;
        supportEmail?: string | null;
        supportPhone?: string | null;
        type: string;
        tz: string;
        defaultCurrencyId: string;
        customDomain?: string | null;
        createdAt: any;
        countryId?: string | null;
        businessUrl?: string | null;
        businessName?: string | null;
        updatedAt: any;
      }>;
      memberAccounts: Array<{
        __typename?: 'AccountMembers';
        id: any;
        accountId: string;
        type: AccountMemberTypesEnum;
        userId: string;
        account: {
          __typename?: 'Accounts';
          id: string;
          name: string;
          logoURI?: string | null;
          ownerId: string;
          supportEmail?: string | null;
          supportPhone?: string | null;
          type: string;
          tz: string;
          defaultCurrencyId: string;
          customDomain?: string | null;
          createdAt: any;
          countryId?: string | null;
          businessUrl?: string | null;
          businessName?: string | null;
          updatedAt: any;
        };
      }>;
    } | null;
  };
};

export type UserAccountsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>;
}>;

export type UserAccountsQuery = {
  __typename?: 'query_root';
  user?: {
    __typename?: 'Users';
    id: string;
    defaultAccountId?: string | null;
    ownerAccounts: Array<{
      __typename?: 'Accounts';
      id: string;
      name: string;
      logoURI?: string | null;
      ownerId: string;
      supportEmail?: string | null;
      supportPhone?: string | null;
      type: string;
      tz: string;
      defaultCurrencyId: string;
      customDomain?: string | null;
      createdAt: any;
      countryId?: string | null;
      businessUrl?: string | null;
      businessName?: string | null;
      updatedAt: any;
    }>;
    memberAccounts: Array<{
      __typename?: 'AccountMembers';
      id: any;
      accountId: string;
      type: AccountMemberTypesEnum;
      userId: string;
      account: {
        __typename?: 'Accounts';
        id: string;
        name: string;
        logoURI?: string | null;
        ownerId: string;
        supportEmail?: string | null;
        supportPhone?: string | null;
        type: string;
        tz: string;
        defaultCurrencyId: string;
        customDomain?: string | null;
        createdAt: any;
        countryId?: string | null;
        businessUrl?: string | null;
        businessName?: string | null;
        updatedAt: any;
      };
    }>;
  } | null;
};

export type GetCoinQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;

export type GetCoinQuery = {
  __typename?: 'query_root';
  coinsByPk?: {
    __typename?: 'Coins';
    id: string;
    logoURI?: string | null;
    name: string;
    symbol: string;
  } | null;
};

export type CountryItemFragment = {
  __typename?: 'Countries';
  id: string;
  dialCode: string;
  emoji: string;
  name: string;
};

export type GetCountriesQueryVariables = Exact<{[key: string]: never}>;

export type GetCountriesQuery = {
  __typename?: 'query_root';
  countries: Array<{
    __typename?: 'Countries';
    id: string;
    dialCode: string;
    emoji: string;
    name: string;
  }>;
};

export type CurrencyItemFragment = {
  __typename?: 'Currencies';
  id: string;
  name: string;
  rate?: any | null;
  symbol: string;
  logoURI?: string | null;
  updatedAt: any;
};

export type GetCurrenciesQueryVariables = Exact<{[key: string]: never}>;

export type GetCurrenciesQuery = {
  __typename?: 'query_root';
  currencies: Array<{
    __typename?: 'Currencies';
    id: string;
    name: string;
    rate?: any | null;
    symbol: string;
    logoURI?: string | null;
    updatedAt: any;
  }>;
};

export type ChainItemFragment = {
  __typename?: 'Chains';
  id: number;
  name: string;
  isTestnet: boolean;
  logoURI?: string | null;
  coinId: string;
};

export type AllChainsQueryVariables = Exact<{[key: string]: never}>;

export type AllChainsQuery = {
  __typename?: 'query_root';
  chains: Array<{
    __typename?: 'Chains';
    id: number;
    name: string;
    isTestnet: boolean;
    logoURI?: string | null;
    coinId: string;
  }>;
};

export type UserItemFragment = {
  __typename?: 'Users';
  id: string;
  fullName?: string | null;
  email: string;
  lastSeen?: any | null;
  phoneNumber?: string | null;
  publicAddress?: string | null;
  type?: string | null;
  defaultAccountId?: string | null;
  createdAt?: any | null;
  countryId?: string | null;
  city?: string | null;
  avatarURI?: string | null;
  address?: string | null;
  updatedAt?: any | null;
};

export type GetUserQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;

export type GetUserQuery = {
  __typename?: 'query_root';
  user?: {
    __typename?: 'Users';
    id: string;
    fullName?: string | null;
    email: string;
    lastSeen?: any | null;
    phoneNumber?: string | null;
    publicAddress?: string | null;
    type?: string | null;
    defaultAccountId?: string | null;
    createdAt?: any | null;
    countryId?: string | null;
    city?: string | null;
    avatarURI?: string | null;
    address?: string | null;
    updatedAt?: any | null;
  } | null;
};

export type AccountItemFragment = {
  __typename?: 'Accounts';
  id: string;
  name: string;
  logoURI?: string | null;
  ownerId: string;
  supportEmail?: string | null;
  supportPhone?: string | null;
  type: string;
  tz: string;
  defaultCurrencyId: string;
  customDomain?: string | null;
  createdAt: any;
  countryId?: string | null;
  businessUrl?: string | null;
  businessName?: string | null;
  updatedAt: any;
};

export type AccountMemberItemFragment = {
  __typename?: 'AccountMembers';
  id: any;
  accountId: string;
  type: AccountMemberTypesEnum;
  userId: string;
};

export type CreateNewAccountMutationVariables = Exact<{
  object?: InputMaybe<AccountsInsertInput>;
}>;

export type CreateNewAccountMutation = {
  __typename?: 'mutation_root';
  insertAccountsOne?: {
    __typename?: 'Accounts';
    id: string;
    logoURI?: string | null;
    name: string;
  } | null;
};

export type ConnectWalletMutationVariables = Exact<{
  message?: InputMaybe<Scalars['String']>;
  signature?: InputMaybe<Scalars['String']>;
}>;

export type ConnectWalletMutation = {
  __typename?: 'mutation_root';
  connectWallet?: {__typename?: 'ConnectWalletOutput'; customToken: string} | null;
};

export type UpdateAccountMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
  _set?: InputMaybe<AccountsSetInput>;
}>;

export type UpdateAccountMutation = {
  __typename?: 'mutation_root';
  account?: {
    __typename?: 'Accounts';
    id: string;
    name: string;
    logoURI?: string | null;
    ownerId: string;
    supportEmail?: string | null;
    supportPhone?: string | null;
    type: string;
    tz: string;
    defaultCurrencyId: string;
    customDomain?: string | null;
    createdAt: any;
    countryId?: string | null;
    businessUrl?: string | null;
    businessName?: string | null;
    updatedAt: any;
  } | null;
};

export type UpdatePaymentMethodMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
  _set?: InputMaybe<PaymentMethodsSetInput>;
}>;

export type UpdatePaymentMethodMutation = {
  __typename?: 'mutation_root';
  updatePaymentMethodsByPk?: {
    __typename?: 'PaymentMethods';
    id: string;
    accountId: string;
    isActive?: boolean | null;
    isDefault?: boolean | null;
    name: string;
    createdAt: any;
    updatedAt: any;
    targetChains: Array<{
      __typename?: 'PaymentMethodChains';
      id: string;
      chainId: number;
      paymentMethodId: string;
      publicAddress: string;
      supportedCoinIds: any;
      supportedCoins?: Array<{
        __typename?: 'Coins';
        id: string;
        logoURI?: string | null;
        name: string;
        symbol: string;
      }> | null;
    }>;
  } | null;
};

export type CoinItemFragment = {
  __typename?: 'Coins';
  id: string;
  logoURI?: string | null;
  name: string;
  symbol: string;
};

export type PaymentMethodChainItemFragment = {
  __typename?: 'PaymentMethodChains';
  id: string;
  chainId: number;
  paymentMethodId: string;
  publicAddress: string;
  supportedCoinIds: any;
};

export type PaymentMethodItemFragment = {
  __typename?: 'PaymentMethods';
  id: string;
  accountId: string;
  isActive?: boolean | null;
  isDefault?: boolean | null;
  name: string;
  createdAt: any;
  updatedAt: any;
};

export type ListPaymentMethodsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentMethodsBoolExp>;
}>;

export type ListPaymentMethodsQuery = {
  __typename?: 'query_root';
  paymentMethodsAggregate: {
    __typename?: 'PaymentMethodsAggregate';
    aggregate?: {__typename?: 'PaymentMethodsAggregateFields'; count: number} | null;
  };
  paymentMethods: Array<{
    __typename?: 'PaymentMethods';
    id: string;
    accountId: string;
    isActive?: boolean | null;
    isDefault?: boolean | null;
    name: string;
    createdAt: any;
    updatedAt: any;
    targetChains: Array<{
      __typename?: 'PaymentMethodChains';
      id: string;
      chainId: number;
      paymentMethodId: string;
      publicAddress: string;
      supportedCoinIds: any;
      supportedCoins?: Array<{
        __typename?: 'Coins';
        id: string;
        logoURI?: string | null;
        name: string;
        symbol: string;
      }> | null;
    }>;
  }>;
};

export type UpdateUserProfileMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
  _set?: InputMaybe<UsersSetInput>;
}>;

export type UpdateUserProfileMutation = {
  __typename?: 'mutation_root';
  user?: {
    __typename?: 'Users';
    id: string;
    fullName?: string | null;
    email: string;
    lastSeen?: any | null;
    phoneNumber?: string | null;
    publicAddress?: string | null;
    type?: string | null;
    defaultAccountId?: string | null;
    createdAt?: any | null;
    countryId?: string | null;
    city?: string | null;
    avatarURI?: string | null;
    address?: string | null;
    updatedAt?: any | null;
  } | null;
};

export class TypedDocumentString<TResult, TVariables>
  extends String
  implements DocumentTypeDecoration<TResult, TVariables>
{
  __apiType?: DocumentTypeDecoration<TResult, TVariables>['__apiType'];

  constructor(private value: string, public __meta__?: {hash: string}) {
    super(value);
  }

  toString(): string & DocumentTypeDecoration<TResult, TVariables> {
    return this.value;
  }
}
export const CountryItemFragmentDoc = new TypedDocumentString(`
    fragment CountryItem on Countries {
  id
  dialCode
  emoji
  name
}
    `) as unknown as TypedDocumentString<CountryItemFragment, unknown>;
export const CurrencyItemFragmentDoc = new TypedDocumentString(`
    fragment CurrencyItem on Currencies {
  id
  name
  rate
  symbol
  logoURI
  updatedAt
}
    `) as unknown as TypedDocumentString<CurrencyItemFragment, unknown>;
export const ChainItemFragmentDoc = new TypedDocumentString(`
    fragment ChainItem on Chains {
  id
  name
  isTestnet
  logoURI
  coinId
}
    `) as unknown as TypedDocumentString<ChainItemFragment, unknown>;
export const UserItemFragmentDoc = new TypedDocumentString(`
    fragment UserItem on Users {
  id
  fullName
  email
  lastSeen
  phoneNumber
  publicAddress
  type
  defaultAccountId
  createdAt
  countryId
  city
  avatarURI
  address
  updatedAt
}
    `) as unknown as TypedDocumentString<UserItemFragment, unknown>;
export const AccountItemFragmentDoc = new TypedDocumentString(`
    fragment AccountItem on Accounts {
  id
  name
  logoURI
  ownerId
  supportEmail
  supportPhone
  type
  tz
  defaultCurrencyId
  customDomain
  createdAt
  countryId
  businessUrl
  businessName
  updatedAt
}
    `) as unknown as TypedDocumentString<AccountItemFragment, unknown>;
export const AccountMemberItemFragmentDoc = new TypedDocumentString(`
    fragment AccountMemberItem on AccountMembers {
  id
  accountId
  type
  userId
}
    `) as unknown as TypedDocumentString<AccountMemberItemFragment, unknown>;
export const CoinItemFragmentDoc = new TypedDocumentString(`
    fragment CoinItem on Coins {
  id
  logoURI
  name
  symbol
}
    `) as unknown as TypedDocumentString<CoinItemFragment, unknown>;
export const PaymentMethodChainItemFragmentDoc = new TypedDocumentString(`
    fragment PaymentMethodChainItem on PaymentMethodChains {
  id
  chainId
  paymentMethodId
  publicAddress
  supportedCoinIds
}
    `) as unknown as TypedDocumentString<PaymentMethodChainItemFragment, unknown>;
export const PaymentMethodItemFragmentDoc = new TypedDocumentString(`
    fragment PaymentMethodItem on PaymentMethods {
  id
  accountId
  isActive
  isDefault
  name
  createdAt
  updatedAt
}
    `) as unknown as TypedDocumentString<PaymentMethodItemFragment, unknown>;
export const InitialDocument = new TypedDocumentString(`
    mutation Initial($avatarURI: String) {
  initial(avatarURI: $avatarURI) {
    user {
      id
      defaultAccountId
      ownerAccounts: accounts {
        ...AccountItem
      }
      memberAccounts: accountMembers {
        ...AccountMemberItem
        account {
          ...AccountItem
        }
      }
    }
  }
}
    fragment AccountItem on Accounts {
  id
  name
  logoURI
  ownerId
  supportEmail
  supportPhone
  type
  tz
  defaultCurrencyId
  customDomain
  createdAt
  countryId
  businessUrl
  businessName
  updatedAt
}
fragment AccountMemberItem on AccountMembers {
  id
  accountId
  type
  userId
}`) as unknown as TypedDocumentString<InitialMutation, InitialMutationVariables>;
export const UserAccountsDocument = new TypedDocumentString(`
    query UserAccounts($userId: String = "") {
  user: usersByPk(id: $userId) {
    id
    defaultAccountId
    ownerAccounts: accounts {
      ...AccountItem
    }
    memberAccounts: accountMembers {
      ...AccountMemberItem
      account {
        ...AccountItem
      }
    }
  }
}
    fragment AccountItem on Accounts {
  id
  name
  logoURI
  ownerId
  supportEmail
  supportPhone
  type
  tz
  defaultCurrencyId
  customDomain
  createdAt
  countryId
  businessUrl
  businessName
  updatedAt
}
fragment AccountMemberItem on AccountMembers {
  id
  accountId
  type
  userId
}`) as unknown as TypedDocumentString<UserAccountsQuery, UserAccountsQueryVariables>;
export const GetCoinDocument = new TypedDocumentString(`
    query getCoin($id: String = "") {
  coinsByPk(id: $id) {
    ...CoinItem
  }
}
    fragment CoinItem on Coins {
  id
  logoURI
  name
  symbol
}`) as unknown as TypedDocumentString<GetCoinQuery, GetCoinQueryVariables>;
export const GetCountriesDocument = new TypedDocumentString(`
    query GetCountries {
  countries {
    ...CountryItem
  }
}
    fragment CountryItem on Countries {
  id
  dialCode
  emoji
  name
}`) as unknown as TypedDocumentString<GetCountriesQuery, GetCountriesQueryVariables>;
export const GetCurrenciesDocument = new TypedDocumentString(`
    query GetCurrencies @cached {
  currencies {
    ...CurrencyItem
  }
}
    fragment CurrencyItem on Currencies {
  id
  name
  rate
  symbol
  logoURI
  updatedAt
}`) as unknown as TypedDocumentString<GetCurrenciesQuery, GetCurrenciesQueryVariables>;
export const AllChainsDocument = new TypedDocumentString(`
    query allChains {
  chains: chains(orderBy: {isTestnet: ASC}) {
    ...ChainItem
  }
}
    fragment ChainItem on Chains {
  id
  name
  isTestnet
  logoURI
  coinId
}`) as unknown as TypedDocumentString<AllChainsQuery, AllChainsQueryVariables>;
export const GetUserDocument = new TypedDocumentString(`
    query GetUser($id: String = "") {
  user: usersByPk(id: $id) {
    ...UserItem
  }
}
    fragment UserItem on Users {
  id
  fullName
  email
  lastSeen
  phoneNumber
  publicAddress
  type
  defaultAccountId
  createdAt
  countryId
  city
  avatarURI
  address
  updatedAt
}`) as unknown as TypedDocumentString<GetUserQuery, GetUserQueryVariables>;
export const CreateNewAccountDocument = new TypedDocumentString(`
    mutation createNewAccount($object: AccountsInsertInput = {}) {
  insertAccountsOne(object: $object) {
    id
    logoURI
    name
  }
}
    `) as unknown as TypedDocumentString<
  CreateNewAccountMutation,
  CreateNewAccountMutationVariables
>;
export const ConnectWalletDocument = new TypedDocumentString(`
    mutation connectWallet($message: String = "", $signature: String = "") {
  connectWallet(message: $message, signature: $signature) {
    customToken
  }
}
    `) as unknown as TypedDocumentString<
  ConnectWalletMutation,
  ConnectWalletMutationVariables
>;
export const UpdateAccountDocument = new TypedDocumentString(`
    mutation updateAccount($id: String = "", $_set: AccountsSetInput = {}) {
  account: updateAccountsByPk(pkColumns: {id: $id}, _set: $_set) {
    ...AccountItem
  }
}
    fragment AccountItem on Accounts {
  id
  name
  logoURI
  ownerId
  supportEmail
  supportPhone
  type
  tz
  defaultCurrencyId
  customDomain
  createdAt
  countryId
  businessUrl
  businessName
  updatedAt
}`) as unknown as TypedDocumentString<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>;
export const UpdatePaymentMethodDocument = new TypedDocumentString(`
    mutation updatePaymentMethod($id: String = "", $_set: PaymentMethodsSetInput = {}) {
  updatePaymentMethodsByPk(pkColumns: {id: $id}, _set: $_set) {
    ...PaymentMethodItem
    targetChains {
      ...PaymentMethodChainItem
      supportedCoins {
        ...CoinItem
      }
    }
  }
}
    fragment CoinItem on Coins {
  id
  logoURI
  name
  symbol
}
fragment PaymentMethodChainItem on PaymentMethodChains {
  id
  chainId
  paymentMethodId
  publicAddress
  supportedCoinIds
}
fragment PaymentMethodItem on PaymentMethods {
  id
  accountId
  isActive
  isDefault
  name
  createdAt
  updatedAt
}`) as unknown as TypedDocumentString<
  UpdatePaymentMethodMutation,
  UpdatePaymentMethodMutationVariables
>;
export const ListPaymentMethodsDocument = new TypedDocumentString(`
    query listPaymentMethods($limit: Int = 20, $offset: Int = 0, $where: PaymentMethodsBoolExp = {}) {
  paymentMethodsAggregate(where: $where) {
    aggregate {
      count
    }
  }
  paymentMethods(
    limit: $limit
    offset: $offset
    where: $where
    orderBy: {isDefault: DESC, isActive: DESC}
  ) {
    ...PaymentMethodItem
    targetChains {
      ...PaymentMethodChainItem
      supportedCoins {
        ...CoinItem
      }
    }
  }
}
    fragment CoinItem on Coins {
  id
  logoURI
  name
  symbol
}
fragment PaymentMethodChainItem on PaymentMethodChains {
  id
  chainId
  paymentMethodId
  publicAddress
  supportedCoinIds
}
fragment PaymentMethodItem on PaymentMethods {
  id
  accountId
  isActive
  isDefault
  name
  createdAt
  updatedAt
}`) as unknown as TypedDocumentString<
  ListPaymentMethodsQuery,
  ListPaymentMethodsQueryVariables
>;
export const UpdateUserProfileDocument = new TypedDocumentString(`
    mutation updateUserProfile($id: String = "", $_set: UsersSetInput = {}) {
  user: updateUsersByPk(pkColumns: {id: $id}, _set: $_set) {
    ...UserItem
  }
}
    fragment UserItem on Users {
  id
  fullName
  email
  lastSeen
  phoneNumber
  publicAddress
  type
  defaultAccountId
  createdAt
  countryId
  city
  avatarURI
  address
  updatedAt
}`) as unknown as TypedDocumentString<
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
>;
