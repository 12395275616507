import {
  AccountItemFragment,
  AccountMemberItemFragment,
  AccountMemberTypesEnum,
} from 'gql/graphql';
import {Account} from 'gql/types';

export const mixAccounts = (
  ownerAccounts: AccountItemFragment[],
  memberAccounts: Array<
    AccountMemberItemFragment & {
      account: AccountItemFragment;
    }
  >
): Account[] => {
  const accounts = ownerAccounts.map<Account>(account => {
    return {
      ...account,
      role: AccountMemberTypesEnum.Owner,
    };
  });
  const foreignAccounts = memberAccounts.map<Account>(m => ({
    ...m.account,
    role: m.type,
  }));
  return [...accounts, ...foreignAccounts];
};
