import {
  FormControl,
  FormLabel,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Switch,
  Tag,
} from '@chakra-ui/react';
import useAppContext from 'hooks/useAppContext';

interface Props {
  isDesktop?: boolean;
}

const TestModeButton = ({isDesktop}: Props) => {
  const {isTestMode, toggleTestMode} = useAppContext();
  return (
    <Popover trigger={isDesktop ? 'hover' : 'click'}>
      <PopoverTrigger>
        <Tag
          cursor='pointer'
          colorScheme={isTestMode ? 'red' : 'brand'}
          w='fit-content'
          h='fit-content'
          alignSelf='center'>
          {isTestMode ? 'Test' : 'Live'}
        </Tag>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader>This view is {!isTestMode ? 'live' : 'test'} data</PopoverHeader>
          <PopoverBody>
            <FormControl display='flex' alignItems='center'>
              <FormLabel htmlFor='test-mode' mb='0'>
                Test mode
              </FormLabel>
              <Switch
                id='test-mode'
                isChecked={isTestMode}
                colorScheme='red'
                onChange={() => toggleTestMode()}
              />
            </FormControl>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default TestModeButton;
