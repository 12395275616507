import {Flex} from '@chakra-ui/react';
import {Outlet} from 'react-router-dom';

import Header from './Header';

const FullScreenLayout = () => {
  return (
    <Flex minH='100vh' flex='1' flexDirection='column' position='relative'>
      <Header />
      <Outlet />
    </Flex>
  );
};

export default FullScreenLayout;
