import {MoonIcon, SunIcon} from '@chakra-ui/icons';
import {IconButton, IconButtonProps, useColorMode} from '@chakra-ui/react';

type Props = Omit<IconButtonProps, 'aria-label'>;

const ToggleColorModeButton = (props: Props) => {
  const {colorMode, toggleColorMode} = useColorMode();
  return (
    <IconButton
      icon={colorMode === 'dark' ? <SunIcon /> : <MoonIcon />}
      aria-label={'Toggle Color Mode'}
      onClick={toggleColorMode}
      variant='ghost'
      {...props}
    />
  );
};

export default ToggleColorModeButton;
