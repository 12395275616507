import AccountController from 'components/AccountController';
import FullscreenLayout from 'components/FullscreenLayout';
import NavbarLayout from 'components/NavbarLayout';
import {createBrowserRouter, Navigate} from 'react-router-dom';
import {lazyRouter} from 'utils/LazyLoadRoutes';

const router = createBrowserRouter([
  {
    path: '/',
    element: lazyRouter(() => import('pages/Home')),
  },
  {
    path: '/',
    element: <FullscreenLayout />,
    children: [
      {
        path: 'account/switch',
        element: lazyRouter(() => import('pages/account/Switch')),
      },
    ],
  },
  {
    path: '/auth/*',
    element: <FullscreenLayout />,
    children: [
      {
        path: 'login',
        element: lazyRouter(() => import('pages/auth/Login')),
      },
      {
        path: 'verify',
        element: lazyRouter(() => import('pages/auth/Verify')),
      },
      {
        path: '*',
        element: <Navigate to='/auth/login' />,
      },
    ],
  },
  {
    path: '/:accountId/*',
    element: (
      <AccountController>
        <NavbarLayout />
      </AccountController>
    ),
    children: [
      {
        path: 'dashboard',
        element: lazyRouter(() => import('pages/Dashboard')),
      },
      {
        path: 'payments/links/create',
        element: lazyRouter(() => import('pages/payments/PaymentLinkCreate')),
      },
      {
        path: 'payments',
        element: lazyRouter(() => import('pages/payments')),
        children: [
          {
            path: '*',
            element: <Navigate to='all' />,
          },
          {
            path: '',
            element: lazyRouter(() => import('pages/payments')),
          },
          {
            path: 'links',
            element: lazyRouter(() => import('pages/payments/PaymentLinks')),
          },
          {
            path: 'all-transactions',
            element: lazyRouter(() => import('pages/payments/AllTransactions')),
          },
        ],
      },
      {
        path: 'settings/profile',
        element: lazyRouter(() => import('pages/settings/profile')),
      },
      {
        path: 'settings',
        element: lazyRouter(() => import('pages/settings')),
        children: [
          {
            path: '*',
            element: <Navigate to='details' />,
          },
          {
            path: 'details',
            element: lazyRouter(() => import('pages/settings/AccountDetails')),
          },
          {
            path: 'payment-methods',
            element: lazyRouter(() => import('pages/settings/paymentMethods')),
          },
          {
            path: 'branding',
            element: lazyRouter(() => import('pages/settings/Branding')),
          },
          {
            path: 'custom-domains',
            element: lazyRouter(() => import('pages/settings/CustomDomains')),
          },
          {
            path: 'members',
            element: lazyRouter(() => import('pages/settings/members')),
          },
        ],
      },
      {
        path: '',
        element: <Navigate to='dashboard' />,
      },
    ],
  },
]);

export default router;
