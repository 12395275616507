import {Avatar, Badge, Button, HStack, Icon, Text} from '@chakra-ui/react';
import {
  chakraComponents,
  GroupBase,
  MenuListProps,
  OptionBase,
  OptionProps,
  Select,
  SingleValueProps,
} from 'chakra-react-select';
import {SizeProp} from 'chakra-react-select/dist/types/types';
import {AccountMemberTypesEnum} from 'gql/graphql';
import useAccount from 'hooks/useAccount';
import {ComponentType, useCallback, useMemo} from 'react';
import {HiOutlinePlusCircle} from 'react-icons/hi2';

interface AccountOption extends OptionBase {
  label: string;
  value: string;
  logoURI?: string;
  role: AccountMemberTypesEnum;
  isDefault?: boolean;
}

type SelectAccountProps = {
  onChange?: (value: string, object: AccountOption) => void;
  value?: string;
  isHeader?: boolean;
  size?: SizeProp;
  onNewAccount?: () => void;
  showButtonNew?: boolean;
};

const SelectAccount = ({
  value,
  onChange,
  isHeader,
  size,
  onNewAccount,
  showButtonNew = true,
}: SelectAccountProps) => {
  const {accounts, currentAccountId} = useAccount();

  const options = useMemo(
    () =>
      (currentAccountId
        ? accounts.sort(a => (a.id === currentAccountId ? -1 : 1))
        : accounts
      ).map<AccountOption>(a => ({
        label: a.name,
        value: a.id,
        logoURI: a.logoURI || undefined,
        role: a.role,
      })),
    [accounts, currentAccountId]
  );

  const defaultOption = useMemo(
    () => (options.length > 0 ? options[0] : undefined),
    [options]
  );

  const singleValue: ComponentType<
    SingleValueProps<AccountOption, false, GroupBase<AccountOption>>
  > = useCallback(
    props => (
      <chakraComponents.SingleValue {...props} key={props.data.value}>
        <HStack>
          <Avatar size='sm' src={props.data.logoURI} name={props.data.label} />
          <Text>{props.data.label}</Text>
        </HStack>
      </chakraComponents.SingleValue>
    ),
    []
  );

  const selected = useMemo(
    () => (value ? options.find(i => i.value === value) : undefined),
    [options, value]
  );

  const customOption: ComponentType<
    OptionProps<AccountOption, false, GroupBase<AccountOption>>
  > = useCallback(
    ({...props}) => (
      <chakraComponents.Option {...props} key={props.data.value}>
        <HStack w='full' justify='space-between'>
          <HStack>
            <Avatar size='sm' src={props.data.logoURI} name={props.data.label} />
            <Text>{props.data.label}</Text>
          </HStack>
          <Badge size='sm'>{props.data.role}</Badge>
        </HStack>
      </chakraComponents.Option>
    ),
    []
  );

  const menulist: ComponentType<
    MenuListProps<AccountOption, false, GroupBase<AccountOption>>
  > = useCallback(
    props => (
      <chakraComponents.MenuList {...props}>
        {props.children}
        <Button
          w='full'
          leftIcon={<Icon as={HiOutlinePlusCircle} />}
          variant='ghost'
          onClick={onNewAccount}>
          New Account
        </Button>
      </chakraComponents.MenuList>
    ),
    [onNewAccount]
  );

  const components = useMemo(
    () =>
      showButtonNew
        ? {
            Option: customOption,
            SingleValue: singleValue,
            MenuList: menulist,
          }
        : {
            Option: customOption,
            SingleValue: singleValue,
          },
    [customOption, menulist, showButtonNew, singleValue]
  );

  return (
    <Select<AccountOption, false, GroupBase<AccountOption>>
      components={components}
      size={size}
      placeholder='Select Account'
      noOptionsMessage={() => 'No account'}
      options={options}
      isSearchable
      colorScheme='gray'
      menuPlacement='auto'
      selectedOptionStyle='check'
      value={selected}
      onChange={v => v && onChange?.(v.value, v)}
      useBasicStyles
      variant={isHeader ? 'unstyled' : 'outline'}
      defaultValue={defaultOption}
      chakraStyles={{
        menu: css => ({
          ...css,
          minW: 'xs',
        }),
      }}
    />
  );
};

export default SelectAccount;
