import {
  As,
  Box,
  ButtonGroup,
  Container,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import TestModeButton from 'components/TestModeButton';
import ToggleColorModeButton from 'components/ToggleColorModeButton';
import useAccount from 'hooks/useAccount';
import {useMemo} from 'react';
import {
  HiOutlineCog6Tooth,
  HiOutlineCube,
  HiOutlinePresentationChartBar,
  HiOutlineQrCode,
  HiOutlineSquares2X2,
  HiOutlineUsers,
} from 'react-icons/hi2';
import {Link} from 'react-router-dom';

import NavLink from './NavLink';
import NavUser from './NavUser';
import {Sidebar} from './Sidebar';
import SwitchAccount from './SwitchAccount';
import {ToggleButton} from './ToggleButton';

export type NavItem = {
  title: string;
  to: string;
  icon: As;
};

export const Navbar = () => {
  const isDesktop = useBreakpointValue({base: false, lg: true});
  const {isOpen, onToggle, onClose} = useDisclosure();
  const {isTestMode, mixAccountUrl, currentAccountId} = useAccount();

  const items = useMemo(
    () =>
      [
        {
          to: mixAccountUrl('/dashboard'),
          title: 'Dashboard',
          icon: HiOutlinePresentationChartBar,
        },
        {
          to: mixAccountUrl('/payments'),
          title: 'Payments',
          icon: HiOutlineQrCode,
        },
        {
          to: mixAccountUrl('/products'),
          title: 'Products',
          icon: HiOutlineCube,
        },
        {
          to: mixAccountUrl('/customers'),
          title: 'Customers',
          icon: HiOutlineUsers,
        },
        {
          to: mixAccountUrl('/integrations'),
          title: 'Integrations',
          icon: HiOutlineSquares2X2,
        },
      ] as NavItem[],
    [mixAccountUrl]
  );

  return (
    <Box
      as='nav'
      bg='bg-surface'
      boxShadow='xs'
      borderBottomWidth={isTestMode ? 'thin' : '0'}
      borderBottomColor='red.500'>
      <Container py={{base: '3', lg: '4'}}>
        <Flex justify='space-between'>
          <HStack spacing='4'>
            <SwitchAccount />
            {isDesktop && (
              <ButtonGroup variant='ghost' spacing='1'>
                {items.map(i => (
                  <NavLink {...i} key={i.to} />
                ))}
              </ButtonGroup>
            )}
          </HStack>
          {isDesktop ? (
            <HStack spacing='4'>
              <ButtonGroup variant='ghost' spacing='1'>
                <TestModeButton isDesktop />
                <ToggleColorModeButton />
                <Link to={mixAccountUrl('/settings')}>
                  <IconButton
                    icon={<HiOutlineCog6Tooth fontSize='1.25rem' />}
                    aria-label='Settings'
                  />
                </Link>
              </ButtonGroup>
              <NavUser isDesktop />
            </HStack>
          ) : (
            <>
              <HStack>
                <TestModeButton />
                <ToggleColorModeButton />
                <ToggleButton isOpen={isOpen} aria-label='Open Menu' onClick={onToggle} />
              </HStack>

              <Drawer
                isOpen={isOpen}
                placement='left'
                onClose={onClose}
                isFullHeight
                preserveScrollBarGap
                // Only disabled for showcase
                trapFocus={false}>
                <DrawerOverlay />
                <DrawerContent>
                  <Sidebar accountId={currentAccountId || ''} items={items} />
                </DrawerContent>
              </Drawer>
            </>
          )}
        </Flex>
      </Container>
    </Box>
  );
};
