import {authExchange} from '@urql/exchange-auth';
import {cacheExchange} from '@urql/exchange-graphcache';
import {refocusExchange} from '@urql/exchange-refocus';
import {requestPolicyExchange} from '@urql/exchange-request-policy';
import {retryExchange} from '@urql/exchange-retry';
import {Buffer} from 'buffer';
import {User} from 'firebase/auth';
import {createClient as createWSClient} from 'graphql-ws';
import {
  createClient,
  errorExchange,
  Exchange,
  fetchExchange,
  subscriptionExchange,
} from 'urql';

import schema from './schema.json';

const isTokenExpired = (token: string) =>
  Date.now() >=
  JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString()).exp * 1000;

export const makeClient = (fireBaseUser?: User) => {
  const url = import.meta.env.VITE_APP_GRAPHQL_ENDPOINT;
  const wsClient = createWSClient({
    url: url.replace('https', 'wss').replace('http', 'ws'),
    lazy: true,
  });

  const exchanges: Exchange[] = [
    requestPolicyExchange({}),
    refocusExchange(),
    cacheExchange({
      schema,
    }),
    errorExchange({
      onError(error, operation) {},
    }),
    authExchange(async utils => {
      console.log('authExchange init');
      let token: string | undefined = undefined;
      if (fireBaseUser) {
        const idToken = await fireBaseUser.getIdTokenResult();
        token = idToken.token;
        console.log(
          'authExchange expirationTime',
          new Date(idToken.expirationTime).toLocaleString()
        );
      }
      console.log('authExchange token', token);
      return {
        addAuthToOperation(operation) {
          if (!token) {
            return operation;
          }
          return utils.appendHeaders(operation, {
            Authorization: `Bearer ${token}`,
          });
        },
        willAuthError: _ => {
          if (token) {
            const isExpired = isTokenExpired(token);
            console.log('willAuthError isExpired', isExpired);
            return isExpired;
          }
          return false;
        },
        didAuthError: error => {
          if (!fireBaseUser) return false;
          console.log('didAuthError', error.graphQLErrors);
          return error.graphQLErrors.some(e => e.extensions?.code === 'invalid-jwt');
        },
        async refreshAuth() {
          token = await fireBaseUser?.getIdToken(true);
          console.log('refreshAuth -> ', token);
        },
      };
    }),
    retryExchange({
      initialDelayMs: 1000,
      maxDelayMs: 15000,
      randomDelay: true,
      maxNumberAttempts: 2,
      retryIf: error => {
        return !!(error.graphQLErrors.length > 0 || error.networkError);
      },
    }),
    fetchExchange,
    subscriptionExchange({
      forwardSubscription(request) {
        const input = {...request, query: request.query || ''};
        return {
          subscribe(sink) {
            const unsubscribe = wsClient.subscribe(input, sink);
            return {unsubscribe};
          },
        };
      },
    }),
  ];
  return createClient({
    url,
    suspense: false,
    exchanges,
  });
};
