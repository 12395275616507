import AppContext from 'AppContext';
import {useCallback, useContext} from 'react';
import {NavigateOptions, useNavigate} from 'react-router-dom';

const useAccount = () => {
  const nav = useNavigate();
  const {
    isLogin,
    isInitializing,
    isTestMode,
    logOut,
    accounts,
    currentAccount,
    setCurrentAccount,
    firebaseUser,
  } = useContext(AppContext);

  const userId = firebaseUser?.uid;
  const currentAccountId = currentAccount?.id;
  const switchAccount = useCallback(
    (accountId: string) => {
      const account = accounts.find(a => a.id === accountId);
      if (account) {
        setCurrentAccount(account);
        localStorage.setItem('selectAccountId', account.id);
        return true;
      }
      return false;
    },
    [accounts, setCurrentAccount]
  );
  const navigate = useCallback(
    (to: string, options?: NavigateOptions) => {
      nav(currentAccountId ? `/${currentAccountId}${to}` : to, options);
    },
    [currentAccountId, nav]
  );
  const mixAccountUrl = useCallback(
    (path: string) => {
      return currentAccountId ? `/${currentAccountId}${path}` : path;
    },
    [currentAccountId]
  );

  return {
    currentAccountId,
    accounts,
    switchAccount,
    navigate,
    userId,
    isLogin,
    mixAccountUrl,
    isInitializing,
    isTestMode,
    logOut,
  };
};

export default useAccount;
