import {As, Button, ButtonProps, HStack, Icon, Text} from '@chakra-ui/react';
import {Link, useMatch} from 'react-router-dom';

interface NavButtonProps extends ButtonProps {
  icon: As;
  title: string;
  to: string;
}

export const NavButton = (props: NavButtonProps) => {
  const {icon, title, to, ...buttonProps} = props;
  const match = useMatch(`${to}*`);
  return (
    <Link to={to}>
      <Button
        variant='ghost'
        justifyContent='start'
        {...buttonProps}
        aria-current={match ? 'page' : 'false'}>
        <HStack spacing='3'>
          <Icon as={icon} boxSize='6' color='subtle' />
          <Text>{title}</Text>
        </HStack>
      </Button>
    </Link>
  );
};
