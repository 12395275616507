import {ColorModeScript} from '@chakra-ui/react';
import {Buffer} from 'buffer';
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

globalThis.Buffer = Buffer;

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ColorModeScript initialColorMode={'light'} />
    <App />
  </React.StrictMode>
);
