import useAccount from 'hooks/useAccount';
import {Navigate, useParams} from 'react-router-dom';

interface Props {
  children: React.ReactNode;
}

const AccountController = ({children}: Props) => {
  const {currentAccountId, isLogin, accounts} = useAccount();
  const {accountId} = useParams();

  const accountRouter = accounts?.find(a => a.id === accountId);

  if (!isLogin) {
    return <Navigate to={'/auth/login'} replace />;
  }

  if (!accountRouter || !currentAccountId) {
    return <Navigate to={'/account/switch'} replace />;
  }

  return <>{children}</>;
};

export default AccountController;
