import {HStack, Image, Text} from '@chakra-ui/react';
import {
  chakraComponents,
  GroupBase,
  OptionBase,
  OptionProps,
  Select,
  SingleValueProps,
} from 'chakra-react-select';
import {graphql} from 'gql';
import {ComponentType, useCallback, useMemo} from 'react';
import {useQuery} from 'urql';

export const CurrencyFragment = graphql(`
  fragment CurrencyItem on Currencies {
    id
    name
    rate
    symbol
    logoURI
    updatedAt
  }
`);

const GetCurrenciesDoc = graphql(`
  query GetCurrencies @cached {
    currencies {
      ...CurrencyItem
    }
  }
`);

interface CurrencyOption extends OptionBase {
  label: string;
  value: string;
  symbol: string;
  logoURI?: string;
}

type SelectCurrencyProps = {
  onChange?: (value: string, object: CurrencyOption) => void;
  value?: string;
  defaultValue?: string;
  onBlur?: () => void;
};

const SelectCurrency = ({value, defaultValue, onBlur, onChange}: SelectCurrencyProps) => {
  const [{data, fetching}] = useQuery({
    query: GetCurrenciesDoc.toString(),
    variables: {},
    requestPolicy: 'cache-and-network',
  });

  const options = useMemo(
    () =>
      data
        ? data?.currencies.map<CurrencyOption>(a => ({
            label: a.name,
            value: a.id,
            symbol: a.symbol,
            logoURI: a.logoURI || undefined,
          }))
        : [],
    [data]
  );

  const singleValue: ComponentType<
    SingleValueProps<CurrencyOption, false, GroupBase<CurrencyOption>>
  > = useCallback(
    props => (
      <chakraComponents.SingleValue {...props} key={props.data.value}>
        <HStack>
          <Image boxSize='6' src={props.data.logoURI} title={props.data.label} />
          <Text>
            {props.data.label} - {props.data.symbol}
          </Text>
        </HStack>
      </chakraComponents.SingleValue>
    ),
    []
  );

  const selected = useMemo(
    () => (value ? options.find(i => i.value === value) : undefined),
    [options, value]
  );

  const selectedDefault = useMemo(
    () => (defaultValue ? options.find(i => i.value === defaultValue) : undefined),
    [options, defaultValue]
  );

  const customOption: ComponentType<
    OptionProps<CurrencyOption, false, GroupBase<CurrencyOption>>
  > = useCallback(
    ({...props}) => (
      <chakraComponents.Option {...props} key={props.data.value}>
        <HStack>
          <Image boxSize='6' src={props.data.logoURI} title={props.data.label} />
          <Text>
            {props.data.label} - {props.data.symbol}
          </Text>
        </HStack>
      </chakraComponents.Option>
    ),
    []
  );

  return (
    <Select<CurrencyOption, false, GroupBase<CurrencyOption>>
      components={{Option: customOption, SingleValue: singleValue}}
      placeholder='Select Currency'
      noOptionsMessage={() => 'No Currency'}
      isLoading={fetching}
      options={options}
      value={selected}
      onChange={v => v && onChange?.(v.value, v)}
      useBasicStyles
      defaultValue={selectedDefault}
      onBlur={onBlur}
    />
  );
};

export default SelectCurrency;
